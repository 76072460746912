import { Box, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import Iconify from "minimals-template/components/Iconify"
import { PagingSearchBarCenter, PagingSearchBarLeft, PagingSearchBarRight } from "slot-definitions"
import { TriFlex } from "./three-segment-flexbox"

export function GenericSearch({
    autoFocus,
    search,
    setSearch,
    searchLabel,
    searchItemName,
    results,
    searchPlaceHolder,
    size = "medium",
    variant = "outlined",
    searchFoundLocation = "right",
    searchFoundSx,
    showResultCount = true,
}) {
    return (
        <>
            <Stack direction={"row"}>
                <Stack direction={"row"} sx={{ width: "100%" }}>
                    <TriFlex>
                        <TriFlex.Left>
                            <Stack spacing={1} direction="row">
                                <PagingSearchBarLeft.Slot />
                            </Stack>
                        </TriFlex.Left>
                        <TriFlex.Center>
                            <PagingSearchBarCenter.Slot>
                                <TextField
                                    autoFocus={autoFocus}
                                    type={"search"}
                                    fullWidth
                                    size={size}
                                    value={search}
                                    placeholder={searchPlaceHolder}
                                    onChange={setFromEvent(setSearch)}
                                    variant={variant}
                                    label={searchLabel}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Iconify icon="eva:search-fill" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </PagingSearchBarCenter.Slot>
                        </TriFlex.Center>
                        <TriFlex.Right>
                            <Stack spacing={1} direction="row">
                                <PagingSearchBarRight.Slot />
                            </Stack>
                        </TriFlex.Right>
                    </TriFlex>
                </Stack>
            </Stack>
            {showResultCount && (
                <Box display="flex" flexDirection="row" mt={1} mb={1}>
                    {searchFoundLocation === "right" && <Box flex={1} sx={{ marginTop: 1 }} />}
                    <Box mr={1} sx={{ color: "text.secondary", ...searchFoundSx }}>
                        <Typography variant="body2">
                            {results.totalCount} {searchItemName.pluralize(results.totalCount)} found{" "}
                            {search.length !== 0 && <>matching &quot;{search}&quot;</>}
                        </Typography>
                    </Box>
                    {searchFoundLocation === "left" && <Box flex={1} sx={{ marginTop: 1 }} />}
                </Box>
            )}
        </>
    )
}

export default GenericSearch
