import { Box, Stack, Typography } from "@mui/material"
import DummyComponent from "lib/@components/debug-component"
import { usePaginatedQuery } from "lib/@hooks/usePaginatedQuery"
import { cloneElement, useState } from "react"
import { GenericPagination } from "./generic-pagination"
import { GenericSearch } from "./generic-search"
import { NoResults } from "../no-results/no-results"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { useBoundValue } from "../binding/use-bound-value"
import { GenericPaginationBar } from "slot-definitions"
import { ToggleDenseSwitch } from "./toggle-dense-switch"
import { useLocation } from "react-router"
import { Bound } from "../binding/Bound"

const queryNoop = () => ({ useResults: [] })

function shouldShowSearch(searchable, search, results) {
    if (search?.length > 0) return true
    if (typeof searchable === "function") return searchable(results)
    if (typeof searchable === "boolean") return searchable
    throw new Error("Invalid parameter type")
}

export function PagingComponent({
    component: Component = DummyComponent,
    autoFocus = true,
    defaultRecords = [5, 10, 25, 50],
    defaultTake = 5,
    disableRowsPerPage = false,
    query = queryNoop,
    defaultOrderStmt,
    queryParams = {},
    searchLabel = "",
    searchPlaceHolder = "",
    searchItemName = "record",
    searchFoundLocation = "right",
    searchFoundSx,
    searchable = true,
    bottom = true,
    top = false,
    showPageInfo = false,
    noResultsText = "No Results",
    toggleDense = false,
    dense = false,
    customSearchComponent = undefined,
    ...props
}) {
    const location = useLocation()

    const searchParams = new URLSearchParams(location.search)
    const urlSkip = searchParams.get("skip")
    const urlTake = searchParams.get("take")

    const initialSkip = urlSkip ? parseInt(urlSkip, 10) : 0
    const initialTake = urlTake ? parseInt(urlTake, 10) : defaultTake || 5

    const [orderStmt, setOrderStmt] = useState(defaultOrderStmt)
    const [sortOrder, setSortOrder] = useState("desc")
    const [orderBy, setOrderBy] = useState()
    const [isDense] = useBoundValue("isDense", dense)

    GenericPaginationBar.usePlug(toggleDense && <ToggleDenseSwitch dense={dense} />)

    if (!defaultRecords.includes(defaultTake)) {
        defaultRecords.unshift(defaultTake)
    }
    queryParams = { ...{ ...queryParams, orderStmt } }
    const {
        changePage,
        changeRowsPerPage,
        currentPage,
        numberOfPages,
        refresh,
        results,
        search,
        setSearch,
        skip,
        take,
    } = usePaginatedQuery({
        query,
        queryParams,
        defaultTake: initialTake,
        defaultSkip: initialSkip,
    })

    const searchProps = {
        autoFocus,
        results,
        search,
        searchLabel,
        searchPlaceHolder,
        searchItemName,
        searchFoundLocation,
        setSearch,
        size: isDense ? "small" : "medium",
        searchFoundSx,
    }
    const searchComponent = customSearchComponent ? (
        cloneElement(customSearchComponent, { ...customSearchComponent.props, searchProps })
    ) : (
        <GenericSearch {...searchProps} />
    )

    if (!results) return <LoadingScreen />
    const pageInfo = getPageInfo(currentPage, results.totalCount, take)
    const canSearch = shouldShowSearch(searchable, search, results)

    if (results.edges.length === 0 && canSearch) {
        return (
            <>
                {searchComponent}
                <NoResults>{noResultsText}</NoResults>
            </>
        )
    }
    return (
        <>
            <Bound refetch={refresh}>
                {canSearch && searchComponent}
                {numberOfPages > 1 && top && (
                    <GenericPagination
                        defaultRecords={defaultRecords}
                        disableRowsPerPage={disableRowsPerPage}
                        take={take}
                        numberOfPages={numberOfPages}
                        changePage={changePage}
                        currentPage={currentPage}
                        changeRowsPerPage={changeRowsPerPage}
                        sortOrder={sortOrder}
                        orderBy={orderBy}
                        showPageInfo={showPageInfo}
                        pageInfo={pageInfo}
                    />
                )}

                <Component
                    skip={skip}
                    numberOfPages={numberOfPages}
                    currentPage={currentPage}
                    queryResults={results}
                    sortOrder={sortOrder}
                    orderBy={orderBy}
                    handleSortBy={handleSortBy}
                    size={isDense ? "small" : "medium"}
                    {...props}
                />

                {numberOfPages > 1 && bottom ? (
                    <GenericPagination
                        defaultRecords={defaultRecords}
                        disableRowsPerPage={disableRowsPerPage}
                        take={take}
                        numberOfPages={numberOfPages}
                        changePage={changePage}
                        currentPage={currentPage}
                        changeRowsPerPage={changeRowsPerPage}
                        sortOrder={sortOrder}
                        orderBy={orderBy}
                        showPageInfo={showPageInfo}
                        pageInfo={pageInfo}
                    />
                ) : (
                    <Box mb={3} />
                )}
            </Bound>
        </>
    )

    function handleSortBy(updatedOrderByField) {
        if (orderBy !== updatedOrderByField) {
            setOrderBy(updatedOrderByField)
            setSortOrder("asc")
            setOrderStmt([`${updatedOrderByField}`])
        } else {
            const updatedSortOrder = sortOrder === "asc" ? "desc" : "asc"
            setSortOrder(updatedSortOrder)
            setOrderStmt([`${updatedSortOrder === "desc" ? "-" : ""}${orderBy}`])
        }
    }

    function getPageInfo(page, totalCount, take) {
        const prevPage = page - 1
        const x = prevPage * take
        const y = page * take > totalCount ? totalCount : page * take
        return (
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ pb: 1.75 }}>
                <Typography>
                    Showing {x + 1} to {y} of {totalCount}
                </Typography>
            </Stack>
        )
    }
}
