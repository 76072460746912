import { changesSince } from "routes/timeline/controller/changes-since"
import { useTreeItemIndex } from "routes/schedule/lib/useTreeItemIndex"
import { Grid, Skeleton, Typography } from "@mui/material"
import AppWidget from "minimals-template/pages/sections/@dashboard/general/app/AppWidget"
import { navigate } from "lib/routes/navigate"

export function TimelineWidget() {
    const sixMonths = Date.create().beginningOfDay().addMonths(-6).toISOString()
    const threeMonths = Date.create().beginningOfDay().addMonths(-3).toISOString()
    const twelveMonths = Date.create().beginningOfDay().addMonths(-12).toISOString()

    const yearChanges = changesSince.useResults({ from: twelveMonths, take: 5000 }) ?? null
    const sixMonthChanges = changesSince.useResults({ from: sixMonths, take: 5000 }) ?? null
    const threeMonthChanges = changesSince.useResults({ from: threeMonths, take: 5000 }) ?? null

    const index = useTreeItemIndex()

    const totalSchedules =
        (index.licensed?.children.length || 0) +
            (index.custom?.children.length || 0) +
            (index.notLicensed?.children.length || 0) || Infinity
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="subtitle2">Schedule Changes</Typography>
            </Grid>
            {threeMonthChanges ? (
                <Grid item xs={12} sm={6} md={4}>
                    <AppWidget
                        component="button"
                        onClick={go(threeMonths)}
                        sx={{ cursor: "pointer" }}
                        title="in last 3 months"
                        color="#2f3998"
                        total={
                            <>
                                {threeMonthChanges.totalSchedules}{" "}
                                <Typography color="text.secondary" variant="caption">
                                    ({threeMonthChanges.changes.filter((f) => !!f.inUse).length} in use)
                                </Typography>
                            </>
                        }
                        chartData={{
                            series: [Math.floor((threeMonthChanges.totalSchedules / totalSchedules) * 100)],
                        }}
                    />
                </Grid>
            ) : (
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton animation="wave" sx={{ height: 135 }} />
                </Grid>
            )}
            {sixMonthChanges ? (
                <Grid item xs={12} sm={6} md={4}>
                    <AppWidget
                        onClick={go(sixMonths)}
                        component="button"
                        sx={{ cursor: "pointer" }}
                        title="in last 6 months"
                        color="#5f51b8"
                        total={
                            <>
                                {sixMonthChanges.totalSchedules}{" "}
                                <Typography color="text.secondary" variant="caption">
                                    ({sixMonthChanges.changes.filter((f) => !!f.inUse).unique("scheduleId").length} in
                                    use)
                                </Typography>
                            </>
                        }
                        chartData={{
                            series: [Math.floor((sixMonthChanges.totalSchedules / totalSchedules) * 100)],
                        }}
                        data-cy="timeline-last-6-months-button"
                    />
                </Grid>
            ) : (
                <Grid item xs={12} sm={6} md={4}>
                    <Skeleton animation="wave" sx={{ height: 135 }} />
                </Grid>
            )}
            {yearChanges ? (
                <Grid item xs={12} md={4}>
                    <AppWidget
                        onClick={go(twelveMonths)}
                        component="button"
                        sx={{ cursor: "pointer" }}
                        title="in last year"
                        color="#5371d8"
                        total={
                            <>
                                {yearChanges.totalSchedules}{" "}
                                <Typography color="text.secondary" variant="caption">
                                    ({yearChanges.changes.filter((f) => !!f.inUse).unique("scheduleId").length} in use)
                                </Typography>
                            </>
                        }
                        chartData={{
                            series: [Math.floor((yearChanges.totalSchedules / totalSchedules) * 100)],
                        }}
                    />
                </Grid>
            ) : (
                <Grid item xs={12} md={4}>
                    <Skeleton animation="wave" sx={{ height: 135 }} />
                </Grid>
            )}
        </>
    )

    function go(time) {
        return function relocate() {
            navigate(`/app/timeline/${time}`)
        }
    }
}
