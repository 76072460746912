import { Box, Grid, Stack } from "@mui/material"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"
import { navigate } from "lib/routes/navigate"
import { useParams } from "react-router-dom"
import { appRoute } from "routes/app/home.runtime"
import { useRootFacilities } from "routes/facilities/lib/use-root-facilities"
import { HelpTag, HELPTAG_MAINTENANCE } from "routes/help/helpTag"
import { RegimeList } from "routes/regime/regime-list"
import { useTreeItem } from "routes/schedule/lib/useTreeItem"
import { FacilityTile } from "routes/schedule/my-schedules/my-group-types/facility/facility-tile"
import { BadgeLabel } from "routes/regime/plugins/regime-title/badge-label"
import { FacilityPageTile } from "slot-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { GroupTile } from "routes/schedule/my-schedules/my-group-types/facility/group-tile"
import { NoResultsContainer } from "lib/@components/no-results/no-results"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { hasDemand } from "lib/authorization/has-demand"

appRoute.register("facilities/:id", <Facilities />)
appRoute.register(
    "facilities",
    //AD: FIXME revisit this ensure permissions correct
    <RegimeList />
)

registerConditionalPageNavigation(
    () => hasDemand("$sharing"),
    "/app/facilities",
    "Maintenance Regimes",
    "pajamas:issue-type-maintenance",
    {
        subPaths: true,
    }
)

function convertHeading(heading) {
    const isLive = heading.startsWith("Live:")
    const isReady = heading.startsWith("Ready:")
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Box>{heading.replace(/[^:]*:/, "")}</Box>

            <BadgeLabel
                color={isLive ? "myStuff" : "secondary"}
                sx={{ zoom: 0.7, lineHeight: 0 }}
                badgeContent={isLive ? "Live" : isReady ? "Candidate" : "Draft"}
            />
        </Stack>
    )
}

export function Facilities() {
    const { id } = useParams()
    const tree = useTreeItem(id)
    const facilities = useRootFacilities(tree)

    return facilities?.length > 0 ? (
        <>
            <HelpTag tags={[HELPTAG_MAINTENANCE]} />
            <PageBreadcrumbContainer
                title={tree.label}
                heading={convertHeading(tree.label)}
                crumb={tree.label.replace(/[^:]*:/, "")}
            >
                <Box flex={1} sx={{ overflowY: "scroll" }} p={1}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        {facilities.map((facility) => (
                            <Grid
                                key={facility.id}
                                item
                                xs={6}
                                md={4}
                                lg={3}
                                sx={{ display: "flex", flexDirection: "column" }}
                            >
                                <Bound parentItem={facility}>
                                    <FacilityPageTile.Slot
                                        type={facility.data.type ?? "group"}
                                        onClick={go(facility)}
                                        facility={facility.data}
                                        parentItem={facility}
                                    />
                                </Bound>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </PageBreadcrumbContainer>
        </>
    ) : (
        <NoResultsContainer message="No facilities to view" />
    )

    function go(facility) {
        return () => {
            navigate(`/app/facilities/facility/${facility.id}`)
        }
    }
}

FacilityPageTile("group").plug(<GroupTile />)
FacilityPageTile("simple").plug(<GroupTile />)
FacilityPageTile("facility").plug(<FacilityTile />)
