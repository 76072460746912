import { Box, Checkbox, FormControlLabel } from "@mui/material"
import { prevent } from "lib/prevent"
import { useSelectedId } from "routes/schedule/lib/useSelectedId"
import { NotInSelector } from "routes/schedule/my-schedules/my-custom-schedules/select-schedule"
import { ScheduleTreeBar, SelectedComponent } from "slot-definitions"
import { BlockClicks } from "lib/@components/block-clicks"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

ScheduleTreeBar.plug(<Selected />)

export function Selected({ id, showLabel, ...props }) {
    const { target, parentItem } = useBoundContext()
    id = id || target.selectedId || target.id
    const [isSelected, toggle, isAbsolute] = useSelectedId([target._id, id])

    if (target.retired) return null

    return (
        <NotInSelector>
            <SelectedComponent.Slot type={parentItem?.id}>
                <BlockClicks>
                    <Box
                        className="selected-box menu-pad"
                        sx={{
                            "& svg": {
                                color: isAbsolute ? "primary.main" : "primary.darker",
                            },
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    className={`checkbox ${isSelected ? "checked" : "not-checked"}`}
                                    {...props}
                                    key={id}
                                    checked={isSelected}
                                    onClick={prevent(() => toggle(isSelected ? false : id))}
                                />
                            }
                            label={showLabel ? "Selected" : undefined}
                        />
                    </Box>
                </BlockClicks>
            </SelectedComponent.Slot>
        </NotInSelector>
    )
}
