export function dataFromEdges(result) {
    return result?.edges?.map("node") ?? []
}

export function replaceNullsWithUndefined(obj) {
    if (obj === null) return undefined
    if (typeof obj !== "object") return obj
    if (Array.isArray(obj)) obj.map(replaceNullsWithUndefined)
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, replaceNullsWithUndefined(value)]))
}

export function lowercaseAndReplaceSpaceWithHyphens(str) {
    if (typeof str !== "string") return ""
    return str.trim().toLowerCase().replace(/\s+/g, "-")
}
