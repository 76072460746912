import { Box, Grid, Link, Typography } from "@mui/material"
import { navigate } from "lib/routes/navigate"
import WidgetCard from "lib/widget-card"
import { useMemo } from "react"
import { getLatestTechnicalBulletin } from "../controller/get-latest-technical-update-bulletin"
import { useReference, useReferenceState } from "lib/@hooks/use-reference"
import { getTechnicalUpdateTitle } from "../routes/technical-update-bulletin-board"
import { truncateTechnicalUpdate } from "lib/truncate-technical-update"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { NoResults } from "lib/@components/no-results/no-results"
import { TechnicalUpdateWidgetCard } from "./technical-update-widget-card"

export function LatestTechnicalBulletinsWidgets() {
    const [technicalBulletinDate] = useReferenceState("latestOpenedTechnicalBulletin")
    const [scheduleBulletinDate] = useReferenceState("latestOpenedScheduleBulletin")

    const {
        data: scheduleUpdate,
        error: scheduleError,
        loading: scheduleLoading,
    } = getLatestTechnicalBulletin.useResults.status("schedule")
    const {
        data: technicalUpdate,
        error: technicalError,
        loading: technicalLoading,
    } = getLatestTechnicalBulletin.useResults.status("technical")

    const showScheduleUnread = !scheduleBulletinDate || scheduleUpdate?.date > scheduleBulletinDate
    const scheduleTruncated = useMemo(
        () => truncateTechnicalUpdate(scheduleUpdate && scheduleUpdate.content),
        [scheduleUpdate]
    )
    const technicalTruncated = useMemo(
        () => truncateTechnicalUpdate(technicalUpdate && technicalUpdate.content),
        [technicalUpdate]
    )
    const [, setTab] = useReference({ technicalUpdatesTab: "technicalBulletins" }, "technicalUpdatesTab")

    if (scheduleLoading || technicalLoading) return <LoadingScreen />

    const showTechnicalUnread = !technicalBulletinDate || technicalUpdate?.date > technicalBulletinDate

    return (
        <>
            <WidgetBox
                subtitle="Technical Updates"
                technicalUpdateTitle={getTechnicalUpdateTitle(technicalUpdate)}
                unread={showTechnicalUnread}
                data={technicalUpdate}
                portedFromLegacy={technicalUpdate?.portedFromLegacy}
                viewAll={() => viewAll("technicalBulletins")}
                content={technicalTruncated}
                errorState={technicalError}
                linkToContentPage={`/app/technical-updates/${technicalUpdate?.type}/${technicalUpdate?.id}`}
                dataCy="technical-updates"
            />
            <WidgetBox
                subtitle="Schedule Updates"
                technicalUpdateTitle={getTechnicalUpdateTitle(scheduleUpdate)}
                unread={showScheduleUnread}
                data={scheduleUpdate}
                portedFromLegacy={scheduleUpdate?.portedFromLegacy}
                viewAll={() => viewAll("scheduleUpdateBulletins")}
                content={scheduleTruncated}
                errorState={scheduleError}
                linkToContentPage={`/app/technical-updates/${scheduleUpdate?.type}/${scheduleUpdate?.id}`}
                dataCy="schedule-updates"
            />
        </>
    )

    function viewAll(tab) {
        setTab({ technicalUpdatesTab: tab })
        navigate("/app/technical-updates/")
    }
}

function WidgetBox({
    subtitle,
    technicalUpdateTitle,
    data,
    portedFromLegacy,
    viewAll,
    content,
    errorState,
    linkToContentPage,
    unread = false,
    dataCy,
}) {
    return (
        <Grid item xs={12} md={6} data-cy={dataCy}>
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="subtitle2">{subtitle}</Typography>
                    <Link variant="subtitle2" onClick={viewAll} role="button">
                        View All
                    </Link>
                </Box>
            </Grid>
            {data && !errorState ? (
                <TechnicalUpdateWidgetCard
                    title={technicalUpdateTitle}
                    portedFromLegacy={portedFromLegacy}
                    linkToContentPage={linkToContentPage}
                    content={content}
                    unread={unread}
                />
            ) : (
                <WidgetCard bannerColor="warning.main">
                    <Box flex={1}>
                        <NoResults mt={0}>No {subtitle} found</NoResults>
                    </Box>
                </WidgetCard>
            )}
        </Grid>
    )
}
