import { Alert, Box, Typography } from "@mui/material"
import { PageBreadcrumbContainer } from "lib/page-breadcrumb-container"
import Iconify from "minimals-template/components/Iconify"
import { Bound } from "lib/@components/binding/Bound"
import { navigate } from "lib/routes/navigate"
import { useState } from "react"
import { OnBulletinEdited } from "event-definitions"
import { confirm } from "lib/@dialogs/confirm"
import { BasicButton } from "lib/@components/basic-button"
import { UpdateForm } from "./update-form"
import { htmlToText } from "lib/html-to-text"
import { TreeToolbar } from "slot-definitions"
import { setAllHyperlinksToTargetBlankInHTML } from "lib/set-all-hyperlinks-to-target-blank-in-HTML"

export function BulletinEditor({ bulletin, onClickPublish, id = null }) {
    const [isBulletinChanged, setIsBulletinChanged] = useState(false)
    const [formIsValid, setFormIsValid] = useState(false)
    const [localBulletin, setLocalBulletin] = useState(bulletin)

    OnBulletinEdited.useEvent((updatedBulletin) => {
        if (!isBulletinChanged) setIsBulletinChanged(true)
        if (htmlToText(bulletin.content)) setFormIsValid(true)
        if (!htmlToText(bulletin.content)) setFormIsValid(false)
        setLocalBulletin(updatedBulletin)
    })

    TreeToolbar.usePlug(
        <BasicButton
            text="Publish"
            icon={<Iconify icon="ic:baseline-publish" />}
            onClick={handlePublish}
            disabled={!formIsValid}
        />,
        [formIsValid]
    )

    const isEdit = id !== null
    const title = isEdit ? "Edit" : "Create"

    return (
        <Bound target={bulletin} formIsValid={formIsValid}>
            <PageBreadcrumbContainer
                title={`${title} Technical Update`}
                parentCrumbs="Technical Updates"
                crumb={`${title} Technical Update`}
                {...(isBulletinChanged && {
                    backButton: (
                        <BasicButton
                            text={"cancel"}
                            onClick={handleCancel}
                            icon={<Iconify icon="ic:baseline-cancel" />}
                        />
                    ),
                })}
            >
                <Box flex={1}>
                    {isEdit && <EditUpdateAlertMessage />}
                    <UpdateForm bulletin={bulletin} />
                </Box>
            </PageBreadcrumbContainer>
        </Bound>
    )

    function handlePublish() {
        localBulletin.content = setAllHyperlinksToTargetBlankInHTML(localBulletin.content)
        onClickPublish(localBulletin)
        navigateToBoard()
    }

    async function handleCancel() {
        if (await confirm("All changes made will be lost", "Cancel changes?")) {
            navigateToBoard()
        }
    }

    function navigateToBoard() {
        navigate("/app/technical-updates/")
    }
}

function EditUpdateAlertMessage() {
    return (
        <Alert severity="info" sx={{ my: 2, mx: 1 }}>
            <Typography variant="subtitle2">Users won&apos;t be alerted to changes made to this update.</Typography>
            <Typography variant="subtitle2">
                If you&apos;re making significant changes, please create a new update.
            </Typography>
        </Alert>
    )
}
