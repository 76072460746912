import { Breadcrumbs, Typography } from "@mui/material"
import { ensureArray } from "lib/ensure-array"
import Iconify from "minimals-template/components/Iconify"

export function Breadcrumb({ sx, rootLabel, routes = [] }) {
    const breadcrumbs = [
        <Typography key="0" variant="body2" color="text.primary">
            {rootLabel}
        </Typography>,
    ]

    ensureArray(routes).map((item, index) =>
        breadcrumbs.push(
            <Typography key={index + 1} variant="body2" color="grey.500">
                {item}
            </Typography>
        )
    )

    return (
        <Breadcrumbs
            sx={{ ...sx }}
            separator={<Iconify icon="ic:baseline-circle" sx={{ height: 4, width: 4, color: "grey.500" }} />}
            aria-label="breadcrumb"
        >
            {breadcrumbs}
        </Breadcrumbs>
    )
}
