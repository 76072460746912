import { Box, Typography } from "@mui/material"
import { DialogBox } from "lib/@components/dialogBox"
import { OneCard } from "lib/@components/one-card"
import { PagedTable } from "lib/@components/paging/paged-table"
import { RegimeAccessTableHead } from "./regime-access-table-head"
import { RegimeAccessUserTableRow } from "./regime-access-table-row"
import { useState } from "react"
import { getPagedUsersForRegimeAccess } from "../controller/get-paged-client-users-for-tree"
import { RegimeAccessChanged } from "event-definitions"
import { useReferenceState } from "lib/@hooks/use-reference"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { RegimeAccessTableRowHeaders, filterStates } from "../regime-access-config"
import Iconify from "minimals-template/components/Iconify"
import { getAllowedRegimeUsers } from "../controller/get-allowed-regime-users"

export function RegimeAccessTableDialog({ regime }) {
    const [selectedUsers, setSelectedUsers] = useState([])
    const [changedUsers, setChangedUsers] = useState({})
    const changedUserIds = Object.keys(changedUsers)
    const permittedUsers = getAllowedRegimeUsers.useResults(regime)

    const refresh = useRefresh(noChange)
    const [accessFilter] = useReferenceState("accessFilter", filterStates.All)
    const { value: filterValue } = accessFilter

    const selectorProps = {
        onSelectAll: handleSelectAll,
        onSelectIndividual: handleSelect,
        selectedItems: selectedUsers,
        rowIdentifier: "userId",
    }

    const titleField = (
        <Box display="flex" alignItems="center">
            <Iconify icon="mdi:account" sx={{ mr: 1 }} />
            <Typography variant="subtitle1">Regime Access</Typography>
        </Box>
    )

    const customSearchComponent = <RegimeAccessTableHead permittedUsers={permittedUsers} changedUsers={changedUsers} />

    return (
        <DialogBox value={changedUsers} title={titleField} okDisabled={() => changedUserIds.length === 0}>
            <Box flex={1}>
                <OneCard sx={{ pr: 1 }}>
                    <PagedTable
                        headers={RegimeAccessTableRowHeaders}
                        query={getPagedUsersForRegimeAccess}
                        queryParams={{
                            regimeId: regime,
                            filterStmt: filterValue,
                            changedUsers: changedUserIds,
                        }}
                        RowComponent={RegimeAccessUserTableRow}
                        customSearchComponent={customSearchComponent}
                        searchItemName="user"
                        searchPlaceHolder={"Search..."}
                        data-cy="sfg20-admin-users-table"
                        toggleDense={true}
                        showPageInfo={true}
                        hasSelector={true}
                        selectorProps={selectorProps}
                        onAccessToggled={toggleAccess}
                        permittedUsers={permittedUsers}
                        changedUsers={changedUsers}
                    />
                </OneCard>
            </Box>
        </DialogBox>
    )

    function handleSelect(selection) {
        const { isSelecting, item } = selection
        const newSelection = isSelecting
            ? [...selectedUsers, item]
            : selectedUsers.filter((selected) => selected !== item)
        setSelectedUsers(newSelection)
    }

    function handleSelectAll(selection) {
        const { isSelecting, items } = selection
        const newSelection = isSelecting
            ? [...selectedUsers, ...items]
            : selectedUsers.filter((selected) => !items.includes(selected))
        setSelectedUsers(newSelection)
    }

    function toggleAccess(desiredState, togglingUser) {
        const selectedArray = Array.from(new Set([togglingUser, ...selectedUsers]))
        const { selected: users, changed } = RegimeAccessChanged.call({
            desiredState,
            selected: selectedArray,
            changed: changedUsers,
        })
        if (users.length === 0) return
        setChangedUsers(changed)
        refresh()
    }
}
