import { appRoute } from "routes/app/home.runtime"
import { TechnicalBulletinBoard } from "./routes/technical-update-bulletin-board"
import { TabbedPage } from "routes/@lib/tabbed-page/tabbed-page.runtime"
import { registerTab } from "lib/@components/tabs"
import { HomePageTile } from "slot-definitions"
import { LatestTechnicalBulletinsWidgets } from "./components/technical-bulletins-widgets"
import { TechnicalUpdateBulletin } from "./routes/technical-update-bulletin"
import "./technical-update.scss"
import { CreateTechnicalUpdate } from "./routes/create-technical-update"
import { EditTechnicalUpdate } from "./routes/edit-technical-update"
import DemandGuard from "minimals-template/components/guards/DemandGuard"

appRoute.register("technical-updates/:type/:id", <TechnicalUpdateBulletin />)
appRoute.register("technical-updates/", <TechnicalUpdates />)
appRoute.register(
    "technical-updates/create-update/:type",
    <DemandGuard demands={["$technicalAuthor", "$!admin"]} and={true}>
        <CreateTechnicalUpdate />
    </DemandGuard>
)
appRoute.register(
    "technical-updates/edit-update/:type/:id",
    <DemandGuard demands={["$technicalAuthor", "$!admin"]} and={true}>
        <EditTechnicalUpdate />
    </DemandGuard>
)

HomePageTile.plug(<LatestTechnicalBulletinsWidgets priority={101} />) //

function TechnicalUpdates() {
    return <TabbedPage tabId="technicalUpdates" pageTitle="Technical Updates" />
}

registerTab({
    tab: "technicalUpdates",
    id: "technicalBulletins",
    title: "Technical Update Bulletins",
    content: <TechnicalBulletinBoard type={"technical"} priority={50} />,
})

registerTab({
    tab: "technicalUpdates",
    id: "scheduleUpdateBulletins",
    title: "Schedule Update Bulletins",
    content: <TechnicalBulletinBoard type={"schedule"} priority={100} />,
})
