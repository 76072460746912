import { createSlot } from "lib/@components/slot/create-slot"

// Selected schedules
export const SelectedSchedulesTopBar = createSlot("SelectedSchedulesTopBar") // The actions bar on selected schedules
export const TagTopBar = createSlot("TagTopBar") // The actions bar on selected schedules
export const BasketTopBar = createSlot("BasketTopBar") // The actions bar on the top of baskets

export const ScheduleSelectorAboveToolbar = createSlot("ScheduleSelectorAboveToolbar")
// Schedule Selector
export const ScheduleSelectorToolbarStart = createSlot("ScheduleSelectorToolbarStart")
export const ScheduleSelectorToolbar = createSlot("ScheduleSelectorToolbar")
export const ScheduleSelectorWrapper = createSlot("ScheduleSelectorWrapper")
export const ScheduleItemWrapper = createSlot("ScheduleItemWrapper")

export const DashboardHeaderLeft = createSlot("DashboardHeaderLeft")

export const RegimeToolbar = createSlot("RegimeToolbar") // The non-scrolling regime toolbar
export const ScheduleSelectorTop = createSlot("ScheduleSelectorTop") // Appears above the top card in BeforeLists
export const ScheduleSelectorBeforeList = createSlot("ScheduleSelectorBeforeList") // Appears before the list of
// schedules and any optional panel,
// requires a subType which is the
// id of the parent of the list
export const ScheduleSelectorPanel = createSlot("ScheduleSelectorPanel") // Appears before the list of schedules,
// requires a subType which is the id of the
// parent of the list
export const ScheduleSelectorList = createSlot("ScheduleSelectorList") // The list of on the schedule selector
export const ScheduleSelectorAfterList = createSlot("ScheduleSelectorAfterList") // Appears after the list of
// schedules, requires a subType which
// is the id of the parent of the list
export const ScheduleSelectorPanels = createSlot("ScheduleSelectorPanels") // The panels used to show the selector view
export const SortModes = createSlot("SortModes") // The sort modes for a list of schedules, must return MenuItems, is
// passed a set function

// Schedules

export const ScheduleRightBar = createSlot("Schedule.RightBar") // The right hand bar when showing schedules
export const ScheduleHeaderToolbar = createSlot("Schedule.HeaderToolbar") // The header toolbar when showing schedules,
// this is the scrolling one next to the
// title
export const ScheduleSummary = createSlot("ScheduleSummary") // Grouping for the types of summary
export const SummaryClassification = ScheduleSummary.subType("Classification") // Summaries for task classification
export const SummarySkilling = ScheduleSummary.subType("Skilling") // Summaries for task skill sets
export const SummaryTiming = ScheduleSummary.subType("Timing") // Summaries for task timing
export const ScheduleDetail = createSlot("ScheduleDetail") // The detail area of the schedule, where the content goes
export const ScheduleIntroductionContentAfter = createSlot("ScheduleIntroductionContentAfter") // Area after schedule
// intro content and
// before intro notes
export const TreeToolbar = createSlot("TreeToolbar") // The non-scrolling toolbar above a schedule and in the tree view
// - it contains the back button
export const BelowTreeToolbar = createSlot("BelowTreeToolbar") // The non-scrolling toolbar above a schedule and in the
// tree view - it contains the back button
export const AboveRightBarTabs = createSlot("AboveRightBarTabs")

export const TreeToolbarLeft = createSlot("TreeToolbarLeft") // The non-scrolling toolbar above a schedule and in the
// tree view - it contains the back button

export const PageBreadCrumbs = createSlot("BreadCrumbs")
// Schedule List View
export const ScheduleItemBeneath = createSlot("ScheduleItemBeneath")
export const ScheduleItemBelow = createSlot("ScheduleItemBelow")
export const ScheduleItemRight = createSlot("ScheduleItemRight")
export const ScheduleItemMain = createSlot("ScheduleItemMain")
export const ScheduleItemIcon = createSlot("ScheduleItemIcon")
export const ScheduleItemMainExtra = createSlot("ScheduleItemMainExtra")
export const SelectorTitle = createSlot("SelectorTitle")
export const SelectorTitleText = createSlot("SelectorTitleText")
export const SelectorTitleExtras = createSlot("SelectorTitleExtras")
export const SelectorTitleRight = createSlot("SelectorTitleRight")

export const ScheduleTitleBelow = createSlot("ScheduleTitleBelow")

// Draft List View
export const DraftItemBelow = createSlot("DraftItemBelow")
export const DraftItemRight = createSlot("DraftItemRight")
export const DraftItemMain = createSlot("DraftItemMain>")
export const PublishedItemBelow = createSlot("PublishedItemBelow")
export const PublishedItemRight = createSlot("PublishedItemRight")
export const PublishedItemMain = createSlot("PublishedItemMain>")

// Search

export const SearchBar = createSlot("SearchBar") // The contents of the search bar

// Navbar

export const NavbarHeader = createSlot("NavbarHeader") // The top of the left nav bar
export const NavbarFooter = createSlot("NavbarFooter") // The foot of the left nav bar

// App Page Decoration

export const BeforeMainStyle = createSlot("BeforeMainStyle") // Before the main style content page
export const MainStyleStart = createSlot("MainStyleStart")
export const AfterMainStyle = createSlot("AfterMainStyle") // After the main style content page
export const BeforeRoute = createSlot("BeforeRoute") // Before the route outlet
export const AfterRoute = createSlot("AfterRoute") // After the route outlet

// Header bar (the top of the app)
export const HeaderBarRight = createSlot("HeaderBarRight") // The content to the left of the account menu

// Task items
export const TaskRowSlots = createSlot("TaskRow") // Task row slots
export const TaskRowBefore = TaskRowSlots("Before")
export const TaskRowAfter = TaskRowSlots("After")
export const TaskRowStart = TaskRowSlots("Start")
export const TaskRowEnd = TaskRowSlots("End")

// Difference headers
export const DifferenceHeader = createSlot("DifferenceHeader") // Group of headers that are sub typed by the type of
// component used

// Difference entries
export const DifferenceEntry = createSlot("DifferenceEntry") // Used to add functionality to every difference - perhaps
// recorfding comments etc? Ratification notes?

// Used to display folders in maintenance models
export const ScheduleFolderLeft = createSlot("ScheduleFolderIcon")
export const ScheduleFolderRight = createSlot("ScheduleFolderRight")
export const ScheduleFolderBelow = createSlot("ScheduleFolderBelow")
export const ScheduleFolderMain = createSlot("ScheduleFolderMain")
export const ScheduleFolderMainExtra = createSlot("ScheduleFolderMainExtra")
export const RegimeButtons = createSlot("RegimeButtons")
export const TimeAndFrequency = createSlot("TimeAndFrequency")
export const SkillSetArea = createSlot("SkillSetArea")
export const UOMArea = createSlot("UOMArea")
export const ClassificationArea = createSlot("ClassificationArea")
export const TaskContent = createSlot("TaskContent")
export const TaskNotes = createSlot("TaskNotes")
export const MyScheduleListItem = createSlot("MyScheduleListItem")
export const StatsTabs = createSlot("StatsTabs")
export const StatsPanels = createSlot("StatsPanels")

export const ScheduleItemCode = createSlot("ScheduleItemCode")
export const PlanToolbar = createSlot("PlanToolbar")
export const PlanReviewToolbar = createSlot("PlanReviewToolbar")

//Admin page
export const AdminTabContainer = createSlot("AdminTabContainer")

export const RegimeItemRight = createSlot("RegimeItemright")
export const RegimeItemLeft = createSlot("RegimeItemleft")
export const RegimeItemTitle = createSlot("RegimeItemTitle")

export const ScheduleTreeBar = createSlot("ScheduleTreeBar")
export const ScheduleTreeBarLeft = createSlot("ScheduleTreeBarLeft")
export const ScheduleTreeBarRight = createSlot("ScheduleTreeBarRight")
export const TaskEditor = createSlot("TaskEditor")

// Allow utility plugins that are always loaded at the core of the app
export const RootPlugs = createSlot("RootPlugs")

// Toolbar on the top row of a conformity document
export const ConformityToolbar = createSlot("ConformityToolbar")
export const ConformityStep = createSlot("ConformityStep")
export const PublishingStep = createSlot("PublishingStep")
export const ConformityStepContent = createSlot("ConformityContent")
// Tiles on the home page
export const HomePageTile = createSlot("HomePageTile")

//Slot used to render an action
export const ActionItem = createSlot("ActionItem")

export const OverviewWidgets = createSlot("OverviewWidgets")

//Schedule Compare
export const ComparisonViewTools = createSlot("ComparisonViewTools") //  Right hand side in line with tabs

//Audit
export const AuditCommentTools = createSlot("AuditCommentTools") //Atop the comment accordion for audit logs
export const AuditEntry = createSlot("AuditEntry") //Each audit entry
export const AuditEntryBelow = createSlot("AuditEntryBelow") //Each audit entry

export const AuditTitle = createSlot("AuditTitle") // Title of an audit section

export const AuditLogEntry = createSlot("AuditLogEntry")

//Facility addons
export const FacilityPlugs = createSlot("FacilityPlugs")

//Facility page tiles
export const FacilityPageTile = createSlot("FacilityPageTile")

//AD
export const PagingSearchBarRight = createSlot("PagingSearchBarRight") //Decorate the GenericSearch in the Paging
// Component
export const PagingSearchBarLeft = createSlot("PagingSearchBarLeft")
export const PagingSearchBarCenter = createSlot("PagingSearchBarCenter")

export const PermissionsTabs = createSlot("PermissionsTabs")
export const PermissionsPanels = createSlot("PermissionsPanels")

export const ClientUserProfileTabs = createSlot("ClientUserProfileTabs")
export const ClientUserProfilePanels = createSlot("ClientUserProfilePanels")

export const ClientTabs = createSlot("ClientTabs")
export const ClientPanels = createSlot("ClientPanels")

export const SelectedComponent = createSlot("SelectedComponent")

export const ScheduleSummaryAdaptor = createSlot("ScheduleSummaryAdaptor")

export const ActionMenu = createSlot("ActionMenu")

// Schedule top bar and breadcrumbs

export const Breadcrumbs = createSlot("Breadcrumbs")

export const ScheduleTopBox = createSlot("ScheduleTopBox")

// Editors for assets
export const AssetEditor = createSlot("AssetEditor")

//GenericPagination
export const GenericPaginationBar = createSlot("GenericPaginationBar")

export const TreeListPage = createSlot("TreeListPage")

export const AuthTemplateHeader = createSlot("AuthTemplateHeader")
export const SharingFilters = createSlot("SharingFilters")

export const StatsOverrides = createSlot("StatsOverrides")
export const ClientMigrations = createSlot("ClientMigrations")
export const AssetActions = createSlot("AssetActions")
export const AssetAvailableSchedule = createSlot("AssetAvailableSchedule")
export const PeriodProfileWrapper = createSlot("PeriodProfileWrapper")
export const SkillProfileWrapper = createSlot("SkillProfileWrapper")
export const CriticalityProfileWrapper = createSlot("CriticalityProfileWrapper")

export const ScheduleSelectorOverrideWrapper = createSlot("ScheduleSelectorOverrideWrapper")

export const TaskSummaries = createSlot("TaskSummaries")

export const AssetAvailableScheduleItems = createSlot("AssetAvailableScheduleItems")

//Action area of a share link box
export const ShareLinkActions = createSlot("ShareLinkActions")
export const ShareLinkCloseLinkRow = createSlot("ShareLinkCloseLinkRow")
export const SystemAdminTabs = createSlot("SystemAdminTabs")
export const SystemAdminPanels = createSlot("SystemAdminPanels")
