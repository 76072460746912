import { ShareLinkActions } from "slot-definitions"
import { DownloadTasksAsExcelSpreadsheet } from "routes/regime/plugins/download-tasks/download-tasks-as-excel-spreadsheet"
import { Feature } from "lib/@components/feature"
import { FEATURE_FLAG_DOWNLOAD_TASKS } from "routes/asset-register/feature_flag_download_tasks"

ShareLinkActions.plug(
    <Feature feature={FEATURE_FLAG_DOWNLOAD_TASKS} priority={50}>
        <DownloadTasksAsExcelSpreadsheet />
    </Feature>
)
