import { Avatar, Checkbox, Stack, Switch, TableCell, TableRow, Typography } from "@mui/material"
import { RolesIcons } from "./roles-icon-widget"
import { relevantDemands } from "../regime-access-config"
import { RegimeAccessChanged } from "event-definitions"
import { useState } from "react"

export function RegimeAccessUserTableRow({ row: user, isSelected, onSelectorClicked, onAccessToggled, changedUsers }) {
    const { userId: id, displayName, demands, hasAccess, jobTitle, photoURL, isCurrentUser } = user

    const [hasBeenChanged, setHasBeenChanged] = useState(Object.prototype.hasOwnProperty.call(changedUsers, id))
    const [isChecked, setIsChecked] = useState(hasBeenChanged ? !hasAccess : hasAccess)

    const demandsLookup = {}
    relevantDemands.forEach((demand) => (demandsLookup[demand] = !!demands?.includes(demand)))
    const [canBeToggled] = useState(!demandsLookup["accessAllRegimes"] || isCurrentUser)

    RegimeAccessChanged.handleOnce(handleAccessChangedEvent)

    return (
        <TableRow className="access-table-row">
            <TableCell>
                <Checkbox disabled={!canBeToggled} checked={isSelected} onClick={handleClickSelector} />
            </TableCell>
            <TableCell>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar
                        src={photoURL}
                        alt={displayName}
                        color="primary"
                        sx={{
                            color: "#333",
                        }}
                    />

                    <Typography data-cy="user-description-name" variant="subtitle2" noWrap>
                        {displayName}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="left">{jobTitle}</TableCell>
            <TableCell align="left">
                <RolesIcons demands={demandsLookup} />
            </TableCell>
            <TableCell>
                <Switch
                    data-cy="regime-access-toggle"
                    disabled={!canBeToggled}
                    checked={isChecked}
                    onChange={() => handleToggle(!isChecked)}
                />
            </TableCell>
        </TableRow>
    )

    function handleClickSelector() {
        if (!canBeToggled) return
        const selection = { isSelecting: !isSelected, item: id }
        onSelectorClicked(selection)
    }

    function handleToggle(newState) {
        if (!canBeToggled) return
        onAccessToggled(newState, id)
    }

    function handleAccessChangedEvent({ desiredState, selected, changed }) {
        if (!selected.includes(id)) return { selected, changed }
        if (isChecked === desiredState) return { selected, changed }
        if (hasBeenChanged) {
            delete changed[id]
            setHasBeenChanged(false)
            setIsChecked(desiredState)
        } else {
            changed[id] = desiredState
            setHasBeenChanged(true)
            setIsChecked(desiredState)
        }
        return { selected, changed }
    }
}
