import { useState } from "react"
import { TextField } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import { DialogBox } from "lib/@components/dialogBox"

export function NameDialog({ title, value = "", label, placeholder }) {
    const [name, setName] = useState(value)
    return (
        <DialogBox title={title} value={name}>
            <TextField
                sx={{ mt: 2 }}
                autoFocus
                placeholder={placeholder}
                fullWidth
                value={name}
                onChange={setFromEvent(setName)}
                variant="standard"
                label={label}
            />
        </DialogBox>
    )
}
