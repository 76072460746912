import { NotOnMobile, OnMobile, useMobile } from "lib/@components/mobile"
import { useScheduleSettings } from "routes/schedule/lib/useScheduleSettings"
import { UpBar } from "routes/schedule/components/up-bar"
import { Box, Chip } from "@mui/material"
import { ScheduleTitle } from "routes/schedule/scheduleTitle"
import { ScheduleBottom, ScheduleIntroduction, ScheduleTopmost } from "routes/schedule/scheduleIntroduction"
import { ScheduleRightBar, ScheduleSummaryAdaptor, SelectorTitleExtras } from "slot-definitions"

import { makeLazy } from "lib/make-lazy"
import { indexTasksInSchedule } from "routes/schedule/lib/index-tasks-in-schedule"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { NotInPdf } from "lib/@components/pdf/not-in-pdf"
import { InPdf } from "lib/@components/pdf/in-pdf"
import { PrintableStack } from "lib/@components/pdf/printable-stack"
import { memo, useState } from "react"
import { Bound } from "lib/@components/binding/Bound"
import { ChangeView, SelectableChanged } from "event-definitions"
import { noChange } from "lib/@hooks/useRefresh"
import { Watermark } from "lib/@components/watermark/watermark"
import { generate } from "library/guid"

export const TaskList = makeLazy(() => import("routes/schedule/tasks/taskList"), "TaskList")
SelectorTitleExtras.plug(
    <Chip if={(c) => c?.schedule?.retired} size="small" sx={{ mx: 1, fontWeight: 400 }} label="Retired" />
)

export const ScheduleDetails = memo(function ScheduleDetails() {
    const [id] = useState(generate)
    const [selections] = useState({})
    const isMobile = useMobile()
    const [{ onePage }] = useScheduleSettings()
    const { schedule, isPDF } = useBoundContext()
    indexTasksInSchedule(schedule)

    SelectableChanged.useRefresh(noChange)
    return (
        <Bound allSelected={selections} key={id}>
            {!!schedule.retired && <Watermark text="Retired" />}
            <PrintableStack key={schedule._id} sx={{ height: 1, flex: 1, overflowY: "auto" }}>
                <NotInPdf>
                    <UpBar inContainer={true} />
                </NotInPdf>
                <NotOnMobile>
                    <PrintableStack
                        sx={{
                            flex: 1,
                            alignItems: "stretch",
                            overflow: "hidden",
                            flexGrow: 1,
                            mx: isMobile ? -1.5 : 0,
                        }}
                        direction="row"
                        spacing={0}
                    >
                        <Box
                            className="schedule-details styled-bullets"
                            sx={{
                                overflowX: "hidden",
                                pr: !isPDF ? 1 : 0,
                                width: isMobile ? `calc(100% + 30px)` : !isPDF ? `calc(100% + 24px)` : "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <ScheduleTitle schedule={schedule} />
                            <ScheduleTopmost.Slot schedule={schedule} />
                            <NotInPdf>
                                {!!onePage && (
                                    <ScheduleSummaryAdaptor.Wrapper>
                                        <ScheduleRightBar.Slot Container={<Box className="one-page" />} />
                                    </ScheduleSummaryAdaptor.Wrapper>
                                )}
                            </NotInPdf>
                            <ChangeView.Refresh>
                                {() =>
                                    Object.values(selections).filter(Boolean).length === 0 && (
                                        <ScheduleIntroduction schedule={schedule} />
                                    )
                                }
                            </ChangeView.Refresh>
                            <TaskList schedule={schedule} />
                            <ScheduleBottom.Slot schedule={schedule} />
                            <InPdf>
                                <Box className="page-break" />
                                <ScheduleSummaryAdaptor.Wrapper>
                                    <ScheduleRightBar.Slot Container={<Box className="one-page" />} />
                                </ScheduleSummaryAdaptor.Wrapper>
                            </InPdf>
                            <NotInPdf>
                                <Box mt={50} />
                            </NotInPdf>
                        </Box>
                        <NotInPdf>
                            {!onePage && (
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        maxWidth: "27vw",
                                        width: 500,
                                        pl: 1,
                                        minWidth: 320,
                                        overflowX: "hidden",
                                        overflowY: "scroll",
                                    }}
                                >
                                    <ScheduleSummaryAdaptor.Wrapper>
                                        <ScheduleRightBar.Slot />
                                    </ScheduleSummaryAdaptor.Wrapper>
                                </Box>
                            )}
                        </NotInPdf>
                    </PrintableStack>
                </NotOnMobile>
                <OnMobile>
                    <Box
                        sx={{
                            flexGrow: 1,
                            overflowX: "hidden",
                            overflowY: "scroll",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <ScheduleTitle schedule={schedule} />
                        <ScheduleSummaryAdaptor.Wrapper>
                            <ScheduleRightBar.Slot Container={<Box />} />
                        </ScheduleSummaryAdaptor.Wrapper>
                        <ScheduleTopmost.Slot schedule={schedule} />
                        <ChangeView.Refresh>
                            {() =>
                                Object.values(selections).filter(Boolean).length === 0 && (
                                    <ScheduleIntroduction schedule={schedule} />
                                )
                            }
                        </ChangeView.Refresh>
                        <TaskList schedule={schedule} />
                        <ScheduleBottom.Slot schedule={schedule} />
                    </Box>
                </OnMobile>
                <InPdf>
                    <Box className="pdf-content-loaded" />
                </InPdf>
            </PrintableStack>
        </Bound>
    )
})
