import { Button } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { RegimeAccessTableDialog } from "./regime-access-dialog"
import { useDialog } from "lib/@hooks/useDialog"
import { grantRegimeAccess } from "../controller/grant-regime-access"
import { removeRegimeAccess } from "../controller/remove-regime-access"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { warningSnackbar } from "lib/snackbar/warning-snackbar"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Secure } from "lib/authorization/secure"
import { Frag } from "lib/@components/slot/frag"

export function RegimeAccessButton() {
    const { parentItem } = useBoundContext()
    const accessManagementDialog = useDialog(<RegimeAccessTableDialog regime={parentItem.id} />, { maxWidth: "lg" })

    return (
        <Secure hide demand="manageAccess" fallback={<Frag />}>
            <Button onClick={openDialog} sx={{ color: "primary.main" }} startIcon={<Iconify icon="ic:round-person" />}>
                Access
            </Button>
        </Secure>
    )

    async function openDialog() {
        const users = await accessManagementDialog()
        if (!users) {
            warningSnackbar("No changes have been saved")
            return null
        }
        try {
            await updateRegimeAccess(users)
            successSnackbar("Permissions successfully updated!")
        } catch (e) {
            errorSnackbar(e)
        }
        return null
    }

    async function updateRegimeAccess(changedUsers) {
        const { toGrant, toRemove } = sortIds()
        await grantRegimeAccess(toGrant, parentItem.id)
        await removeRegimeAccess(toRemove, parentItem.id)

        function sortIds() {
            return Object.entries(changedUsers).reduce(
                (acc, [id, value]) => {
                    value ? acc.toGrant.push(id) : acc.toRemove.push(id)
                    return acc
                },
                { toGrant: [], toRemove: [] }
            )
        }
    }
}
