import { Bound } from "lib/@components/binding/Bound"
import { usePayloadTask } from "routes/facilities/lib/use-payload-task"
import { clone } from "lib/clone"
import { useRefresh } from "lib/@hooks/useRefresh"
import { Payload } from "routes/schedule/controller/payloads"
import { Different } from "routes/regime/components/different"
import { Time } from "routes/schedule/tasks/time"
import { Frequency } from "routes/schedule/tasks/frequency"
import { periodDuration } from "routes/schedule/lib/usePeriodTimingsPerTask"
import { SummaryDataChanged } from "event-definitions"
import { assessStatus } from "routes/schedule/extendedSchedule"
import { BlockClicks } from "lib/@components/block-clicks"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function EditTimings() {
    const { target, schedule, payload } = useBoundContext()
    const task = usePayloadTask()
    task.minutes ??= target.minutes
    task.frequency ??= clone(target.frequency)
    const refresh = useRefresh()
    return (
        <Payload>
            <BlockClicks>
                <Bound onChange={validate} editMode={true} target={task}>
                    <Different field="minutes" comparedTo={target}>
                        <Time />
                    </Different>
                    <Different field="frequency" comparedTo={target}>
                        <Frequency />
                    </Different>
                </Bound>
            </BlockClicks>
        </Payload>
    )

    function validate() {
        if (periodDuration(task) < periodDuration(target) && target.classification === "Red") {
            task.frequency = clone(target.frequency)
            refresh()
        }
        assessStatus(payload, schedule)
        SummaryDataChanged.raiseOnce()
    }
}
