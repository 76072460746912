import { gql } from "@apollo/client"
import { Box, ListItemButton, Stack, Typography } from "@mui/material"
import { ScheduleFolderIcon } from "event-definitions"
import { Bound } from "lib/@components/binding/Bound"
import { clone } from "lib/clone"
import { PropertyBox } from "lib/@components/property-box"
import { ListItemBox } from "lib/@components/ListItemBox"
import { prevent } from "lib/prevent"
import { GROUP, isGroup, isNotGroup } from "library/constants"
import { uniqueId } from "library/database/split-id"
import { MapPlus } from "library/map-plus"
import { getClient } from "minimals-template/components/contexts/NognitoContext"
import { getTreeId } from "routes/schedule/lib/getTreeId"
import { moveScheduleToGroup } from "routes/schedule/lib/move-schedule-to-group"
import { ScheduleFolder } from "routes/schedule/my-schedules/my-custom-schedules/schedule-folder"
import { find } from "routes/schedule/plugins/baskets/tree/baskets-tree.runtime"
import { FolderIcon } from "routes/schedule/tree/schedules/folder-icon"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { parentTreeItem } from "routes/schedule/tree/schedules/makeTreeItemsFromGroup"
import { captureNow } from "FrozenRouter"
import { query } from "lib/graphql/query"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import noop from "lib/noop"

function getParents(currentGroup) {
    const parents = []
    while (currentGroup && currentGroup.data?.type !== "facility") {
        if (typeof currentGroup.label === "string") {
            parents.push({ label: currentGroup.label, id: currentGroup.id })
        }
        currentGroup = currentGroup[parentTreeItem]
    }
    return parents
}

export function SmartFolder({ color, caption, children, last, Component = ListItemButton }) {
    const { noDivider, onClick, selected } = useBoundContext()
    last = last || noDivider
    return (
        <Component divider={!last} onClick={captureNow(prevent(onClick))} selected={selected}>
            <PropertyBox spacing={0}>
                <ListItemBox spacing={1} ml={-1} sx={{ lineHeight: 0 }}>
                    <FolderItemPart>
                        <FolderIcon sx={{ color }} icon="ic:baseline-folder-special" />
                    </FolderItemPart>
                    <Box flex={1} pl={8.5} sx={{ color: "primary.main", textTransform: "capitalize" }}>
                        <Box mr={1}>{caption}</Box>
                        {children}
                    </Box>
                    <Box>
                        <Typography variant="caption" sx={{ color: "text.secondary" }}>
                            ✱ Smart Folder
                        </Typography>
                    </Box>
                </ListItemBox>
            </PropertyBox>
        </Component>
    )
}

export async function retrieveClientTree(root, type, id = getClient(), map = (v) => v, onGroup = noop) {
    try {
        const groups = new MapPlus(() => [])
        if (!getClient()) return []
        const {
            groupToSchedules: { groupTree },
        } = await query(
            gql`
                query getClientTree($tree: String!) {
                    groupToSchedules(tree: $tree) {
                        groupTree
                    }
                }
            `,
            { tree: type && !id.startsWith(type) ? `${type}|${id}` : id }
        )
        const items = recurseAdd(clone(groupTree.children), root)
        onGroup(groupTree)
        if (process.env.REACT_APP_SMART_FOLDER) {
            // eslint-disable-next-line prefer-const
            for (let [group, list] of Array.from(groups.entries()).filter(([, list]) => list.length > 1)) {
                group = group.titleize()
                const entry = {
                    id: `smart_${group}`,
                    isSmart: true,
                    label: `✱ ${group}`,
                    visible: true,
                    data: {},
                    folderHandling: false,
                    hasChildren: true,
                    type: GROUP,
                    content: <SmartFolder caption={`${group}`} color="primary.darker" />,
                    children: list
                        .map((folder) => folder.children.filter(isNotGroup))
                        .flat(Infinity)
                        .map((c) => {
                            const schedule = {
                                ...c,
                                realId: c.id,
                                selectedId: c.id,
                                id: `smart_${group}!${uniqueId(c._id)}`,
                            }
                            return decorateSchedule(schedule, { transformContent })

                            function transformContent(v) {
                                return (
                                    <Bound target={schedule} parentItem={c[parentTreeItem]}>
                                        <v.type
                                            {...v.props}
                                            schedule={{
                                                ...v.props.schedule,
                                                selectedId: schedule.realId,
                                                label: (
                                                    <Stack alignItems="center" direction="row">
                                                        <Typography variant="body1" sx={{ mr: 1 }}>
                                                            {v.props.schedule.label}
                                                        </Typography>
                                                        <Typography variant="body2" sx={{ color: "text.secondary" }}>
                                                            {getParents(v.props.schedule)
                                                                .slice(1)
                                                                .reverse()
                                                                .map("label")
                                                                .join(" › ")}
                                                        </Typography>
                                                    </Stack>
                                                ),
                                            }}
                                        />
                                    </Bound>
                                )
                            }
                        }),
                }
                items.push(entry)
            }
        }

        return items

        // eslint-disable-next-line no-inner-declarations
        function recurseAdd(children, parent, items = []) {
            children
                .sortBy((c) => `${isGroup(c) ? "A" : "z"}${c.label}`)
                .forEach((child) => {
                    child = map(child)
                    if (isGroup(child)) {
                        const entry = {
                            ...child,
                            data: child.data ?? {},
                            $: child,
                            [parentTreeItem]: parent,
                            visible: true,
                            hasChildren: true,
                            folderHandling: root.folderHandling,
                            type: GROUP,
                            children: [],
                        }
                        if (!entry.data?.type) {
                            groups.get(entry.label.toLowerCase()).push(entry)
                        }
                        items.push(entry)
                        const { icon } = ScheduleFolderIcon.call({ icon: undefined, data: child })

                        entry.content = (
                            <ScheduleFolder
                                color="myStuff.main"
                                caption={child.data?.name ?? child.label}
                                entry={entry}
                                icon={icon}
                            />
                        )
                        recurseAdd(child.children, entry, entry.children)
                    } else {
                        const tree = getTreeId(child.id)
                        const childSchedule = {
                            ...child,
                            isAlias: true,
                            id: moveScheduleToGroup(uniqueId(child._id), parent.id, tree),
                            [parentTreeItem]: parent,
                        }
                        const entry = decorateSchedule(childSchedule)
                        items.push(entry)
                    }
                })
            return items
        }
    } catch (e) {
        console.error("Failed to get tree", root, type, id, e)
        return []
    }
}

function decorateSchedule(childSchedule, { transformContent = (v) => v } = {}) {
    let content
    let retrieved
    return Object.defineProperties(childSchedule, {
        label: {
            get() {
                return (retrieved ??= find(childSchedule._id))?.label ?? "n/a"
            },
        },
        content: {
            get() {
                if (content) return content
                const otherContent = (retrieved ??= find(childSchedule._id))?.content ?? null
                if (otherContent) {
                    return (content = transformContent(
                        <otherContent.type {...otherContent.props} schedule={childSchedule} />
                    ))
                }
                return null
            },
        },
        $: {
            get() {
                return (retrieved ??= find(childSchedule._id))?.$
            },
        },
    })
}
