import { Box, Divider, List, Paper, Typography } from "@mui/material"
import { ActionEntry } from "routes/actions/action-entry"
import { ListItemBox } from "lib/@components/ListItemBox"
import { Bound } from "lib/@components/binding/Bound"
import { useState } from "react"
import { ListFilters } from "routes/actions/list-filters"
import { NoResults } from "lib/@components/no-results/no-results"
import { RequestPageRepeat } from "lib/@components/request-page-repeat"
import { useSearchParam } from "lib/@hooks/use-search-param"

export function ActionList({ sx }) {
    const [total, setTotal] = useState(0)
    const [skip, setSkip] = useSearchParam("skip", 0, (v) => +v)
    const [list, setList] = useState([])
    const [complete, setComplete] = useState(false)

    return (
        <Bound setList={setList} skip={skip} setComplete={setComplete} setTotal={setTotal}>
            <Paper
                elevation={1}
                sx={{
                    overflow: "hidden",
                    ...sx,
                }}
            >
                <ListItemBox alignItems="flex-start">
                    <ListFilters />
                    <Divider orientation="vertical" flexItem />
                    <List
                        sx={{
                            flex: 1,
                            py: 0,
                            "& .MuiButton-root": {
                                display: "block",
                                minWidth: "max-content !important",
                                flexBasis: "max-content",
                            },
                            "& .MuiListItemText-root": {
                                flexBasis: 0,
                                mr: 3,
                            },
                            width: "calc(100% - 240px)", //240 is the width of the left hand side of table
                        }}
                    >
                        <ListItemBox sx={{ width: 1, py: 0.3, bgcolor: "grey.50016" }}>
                            {!complete && (
                                <>
                                    <Box ml={6}>
                                        <Typography variant="caption" color="text.secondary">
                                            Action
                                        </Typography>
                                    </Box>
                                    <Box flex={1} />
                                    <Box mr={3}>
                                        <Typography variant="caption" color="text.secondary">
                                            Created
                                        </Typography>
                                    </Box>
                                    <Box mr={7}>
                                        <Typography variant="caption" color="text.secondary">
                                            Due
                                        </Typography>
                                    </Box>
                                </>
                            )}
                            {!!complete && (
                                <>
                                    <Box ml={2}>
                                        <Typography variant="caption" color="text.secondary">
                                            Action
                                        </Typography>
                                    </Box>
                                    <Box flex={1} />
                                    <Box mr={3}>
                                        <Typography variant="caption" color="text.secondary">
                                            Completed
                                        </Typography>
                                    </Box>
                                    <Box mr={3.75}>
                                        <Typography variant="caption" color="text.secondary">
                                            By
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </ListItemBox>
                        {!!list?.length && (
                            <RequestPageRepeat
                                setSkip={setSkip}
                                total={total}
                                pageSize={10}
                                list={list}
                                pass="action"
                                item={<ActionEntry />}
                            />
                        )}
                        {!list?.length && <NoResults width={150} mt={2} />}
                    </List>
                </ListItemBox>
            </Paper>
        </Bound>
    )
}
