import { registerTab } from "lib/@components/tabs"
import { Lazy } from "lib/make-lazy"

registerTab({
    tab: "sfg20AdminTabs",
    id: "exportSchedule",
    title: "Export Schedule",
    demands: "$technicalAuthor",
    content: <Lazy priority={20000} importer={() => import("./export-schedule")} extract="ExportSchedule" />,
})
