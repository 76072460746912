import { Box, Chip, Stack, Typography } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { ScheduleHeaderToolbar, ScheduleTitleBelow, SelectorTitleRight } from "slot-definitions"
import { BoundEditableText } from "lib/@components/editable/editableText"
import { ContextMenuBar } from "lib/@components/context-menu-bar"
import { NotOnMobile, OnMobile } from "lib/@components/mobile"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { RenderIfHasContent } from "lib/render-as-html"
import { alpha } from "@mui/material/styles"
import uniqolor from "uniqolor"
import { SearchFor } from "event-definitions"
import { BlockClicks } from "lib/@components/block-clicks"
import { Frag } from "lib/@components/slot/frag"
import { NotInPdf } from "lib/@components/pdf/not-in-pdf"
import { BoundContext } from "lib/@components/binding/bound-context"
import { Tags } from "routes/schedule/plugins/baskets/tags"

export function ScheduleTitle() {
    const { hideToolbar, schedule, target, editMode, isPDF, showEditor, isPreview } = useBoundContext()
    const scheduleHeader = ScheduleHeaderToolbar.useSlot(<Frag />, { schedule })
    return (
        <Stack spacing={1}>
            <ListItemBox mb={1} mt={1} spacing={1}>
                {(editMode || isPDF || showEditor || isPreview) && (
                    <Box flex={1}>
                        <Typography variant={"h4"} component={"h1"}>
                            <BoundEditableText readOnly field="code" /> <BoundEditableText field="title" />
                            {target.retired && (
                                <Box pl={1} display="inline-block" color="text.secondary">
                                    (Retired)
                                </Box>
                            )}
                        </Typography>
                    </Box>
                )}
                <OnMobile>
                    <SelectorTitleRight.Plug>
                        <RenderIfHasContent priority={300} content={scheduleHeader}>
                            <Stack direction="row" flex={0.01}>
                                <Box flex={1} />
                                <ContextMenuBar type="schedule" priority={1000}>
                                    {scheduleHeader}
                                </ContextMenuBar>
                            </Stack>
                        </RenderIfHasContent>
                    </SelectorTitleRight.Plug>
                </OnMobile>
                <NotOnMobile>
                    {!hideToolbar && (
                        <SelectorTitleRight.Plug>
                            <ScheduleHeaderToolbar.Slot priority={300} schedule={schedule}>
                                <Stack direction="row" flex={0.01}>
                                    <Box flex={1} />

                                    <ContextMenuBar type="schedule" priority={1000} />
                                </Stack>
                            </ScheduleHeaderToolbar.Slot>
                        </SelectorTitleRight.Plug>
                    )}
                </NotOnMobile>
            </ListItemBox>
            <ScheduleTitleBelow.Slot />
        </Stack>
    )
}

/*
 function CoreTags() {
 const { schedule } = useBoundContext()
 return (
 !!schedule.coreTagDescriptions?.length && (
 <>
 <Box>
 <Typography component="div" color="text.secondary" variant="caption" sx={{ mb: 1.5 }}>
 Core Tags
 </Typography>
 <Stack direction="row" spacing={1}>
 <Repeat list={schedule.coreTagDescriptions} item={<CoreTag />} />
 </Stack>
 </Box>
 <Divider orientation="vertical" />
 </>
 )
 )
 }
 */

ScheduleTitleBelow.plug(
    <Stack direction="row" spacing={1}>
        {/*<CoreTags />*/}
        <NotInPdf>
            <BoundContext>
                {({ hideUpBar, isShare, schedule, isTimeline }) =>
                    !hideUpBar &&
                    !isShare &&
                    !isTimeline && (
                        <Box flex={1}>
                            <Typography component="div" color="text.secondary" variant="caption" gutterBottom>
                                Custom Tags
                            </Typography>
                            <Tags referenceId={schedule._id} />
                        </Box>
                    )
                }
            </BoundContext>
        </NotInPdf>
    </Stack>
)

export function CoreTag({ item }) {
    return (
        <BlockClicks>
            <Chip
                onClick={search}
                sx={{
                    bgcolor: alpha(
                        uniqolor(item, {
                            saturation: [55, 99],
                            lightness: [70, 90],
                        }).color,
                        0.5
                    ),
                }}
                label={item.titleize()}
            />
        </BlockClicks>
    )

    function search() {
        SearchFor.raise(`coretag:"${item}"`)
    }
}
