import { TaskNotes } from "slot-definitions"
import { TaskSteps } from "routes/schedule/plugins/ai-steps/task-steps"
import { registerFeature } from "lib/features"
import { Feature } from "lib/@components/feature"

export const CHECKLIST_FEATURE = "check-list"
TaskNotes.plug(
    <Feature priority={200} feature={CHECKLIST_FEATURE}>
        <TaskSteps />
    </Feature>
)
registerFeature("Check Lists", CHECKLIST_FEATURE)
