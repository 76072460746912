import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { AuditCommentTools, AuditEntry, AuditEntryBelow, AuditLogEntry, AuditTitle } from "slot-definitions"
import "./compare-previous-schedule-button"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { NoResults } from "lib/@components/no-results/no-results"
import { UserAvatar } from "minimals-template/components/user-avatar"
import { useUser } from "lib/user/use-user"
import { Bound } from "lib/@components/binding/Bound"
import { DATE_FORMATS } from "lib/@constants/date-format"
import noop from "lib/noop"

export function AuditLog() {
    const { data, auditId: id, type } = useBoundContext()
    const formattedData = formatData(data)
    return formattedData.length > 0 ? (
        formattedData?.map((group, i) => (
            <Bound key={i} group={group}>
                <AuditLogEntry.Slot key={i} group={group} index={i} type={type}>
                    <Accordion
                        key={id}
                        sx={{
                            my: "1px !important",
                            boxShadow: "inset 0px -1px 0px rgba(145, 158, 171, 0.24)",
                            "&:before": { display: "none" },
                        }}
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            expandIcon={
                                <Iconify icon="ic:round-chevron-right" width="24px" height="24px" rotate="90deg" />
                            }
                            id={`auditAccordionTitle-${group.id}`}
                        >
                            <AuditTitle.Slot type={type} group={group}>
                                <Typography variant={"subtitle1"}>Version {group.version}</Typography>
                            </AuditTitle.Slot>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AuditCommentTools.Slot group={group} id={id} type={type} index={i} />
                            {group.content.map((groupData) => (
                                <AuditComment type={type} key={groupData.id} {...groupData} />
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </AuditLogEntry.Slot>
            </Bound>
        ))
    ) : (
        <NoResults />
    )
}

function AuditComment(props) {
    const { id, userId, userName, date, desc } = props
    const { user } = useUser(userId)
    const { close = noop } = useBoundContext()
    return (
        <Box sx={{ my: 2, display: "flex" }} className="audit-comment">
            <Box sx={{ m: 1, mr: 2 }}>
                <UserAvatar sx={{ width: 32, height: 32 }} user={user} />
            </Box>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ my: 1 }}>
                    <Typography variant={"caption"} sx={{ color: "text.secondary" }} id={`auditUserDateInfo-${id}`}>
                        {userName}, {date}
                    </Typography>
                </Box>
                <Box sx={{ p: 1.5, bgcolor: "background.neutral", borderRadius: 1 }}>
                    <Stack direction="row" spacing={1}>
                        <Box flex={1}>
                            <Typography variant={"body2"} id={`auditInfo-${id}`}>
                                {desc}
                            </Typography>
                        </Box>
                        <Box onClick={close}>
                            <AuditEntry.Slot {...props} />
                        </Box>
                    </Stack>

                    <AuditEntryBelow.Slot {...props} />
                </Box>
            </Box>
        </Box>
    )
}

function formatData(data) {
    const groupedData = Object.entries(data.groupBy("referenceId")).map(([id, content]) => ({
        id,
        version: id.split("-version:")[1],
        content: Object.entries(content).map(([, data]) => ({
            id: data.id,
            referenceId: data.referenceId,
            userId: data.userId,
            userName: data.userName,
            date: new Date(data.date).format(DATE_FORMATS.long),
            desc: data.shortDescription,
        })),
    }))
    return groupedData
}
