import { Alert, Grid, Stack, Typography } from "@mui/material"
import { PageBreadCrumbs, TreeToolbar } from "slot-definitions"
import { Frag } from "lib/@components/slot/frag"
import { DATE_FORMATS } from "lib/@constants/date-format"
import { truncateTechnicalUpdate } from "lib/truncate-technical-update"
import { useReferenceState } from "lib/@hooks/use-reference"
import { TechnicalUpdateWidgetCard } from "../components/technical-update-widget-card"
import { NoResults } from "lib/@components/no-results/no-results"
import { getTechnicalUpdateBulletins } from "../controller/get-technical-update-bulletins"
import LoadingScreen from "minimals-template/components/LoadingScreen"
import { selectLocalStorageType, updateTypeEnum } from "../technical-update-consts"
import { capitalCase } from "change-case"
import Iconify from "minimals-template/components/Iconify"
import { navigate } from "lib/routes/navigate"
import { BasicButton } from "lib/@components/basic-button"
import { Secure } from "lib/authorization/secure"

export function TechnicalBulletinBoard({ type }) {
    const localStorageType = selectLocalStorageType(type)
    const [bulletinDate] = useReferenceState(localStorageType)
    const { data: results, loading } = getTechnicalUpdateBulletins.useResults.status(type)
    const latest = results && results[0]
    if (loading) return <LoadingScreen />
    if (!results) return <NoResults />
    return (
        <Stack>
            <PageBreadCrumbs.Plug>
                <Frag>{`${capitalCase(type)} Update Bulletins`}</Frag>
            </PageBreadCrumbs.Plug>
            <Secure demand={["$technicalAuthor", "$!admin"]} fallback={<Frag />}>
                <TreeToolbar.Plug>
                    <BasicButton
                        text="New Update"
                        onClick={navigateToCreate}
                        icon={<Iconify icon="ic:baseline-plus" />}
                    />
                </TreeToolbar.Plug>
            </Secure>
            {type === updateTypeEnum.SCHEDULE && <ScheduleUpdateBulletinStartingAdornment />}
            <Grid container spacing={2} sx={{ width: 1 }}>
                {results?.map((update) => {
                    const { portedFromLegacy, id, content, date } = update
                    const truncatedContent = truncateTechnicalUpdate(content)
                    const linkToContentPage = `/app/technical-updates/${type}/${id}`
                    const technicalUpdateTitle = getTechnicalUpdateTitle(update)
                    const lastReadOutofDate = !bulletinDate || date > bulletinDate
                    const showTechnicalUnread = update === latest ? lastReadOutofDate : false

                    return (
                        <Grid key={id} item xs={12} sm={6} md={4}>
                            <TechnicalUpdateWidgetCard
                                title={technicalUpdateTitle}
                                portedFromLegacy={portedFromLegacy}
                                linkToContentPage={linkToContentPage}
                                content={truncatedContent}
                                unread={showTechnicalUnread}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Stack>
    )

    function navigateToCreate() {
        navigate(`/app/technical-updates/create-update/${type}`)
    }
}

function ScheduleUpdateBulletinStartingAdornment() {
    return (
        <Alert severity="info" sx={{ mt: 1, mr: 2 }}>
            <Typography variant="subtitle2">
                Keeping you informed on technical information updates to SFG20 Maintenance Task Schedules.
            </Typography>
            <Typography variant="subtitle2">
                As part of our continuous improvements to SFG20 the technical team review schedule content regularly and
                update the schedules accordingly.
            </Typography>
            <Typography variant="subtitle2">Details of these updates are notified below.</Typography>
        </Alert>
    )
}

export function getTechnicalUpdateTitle(technicalUpdate) {
    if (!technicalUpdate) return ""
    return technicalUpdate.title ? technicalUpdate.title : new Date(technicalUpdate.date).format(DATE_FORMATS.medium)
}
