import { useEffect, useRef, useState } from "react"
import { useMobile } from "lib/@components/mobile"
import { Button, InputAdornment, Stack, TextField } from "@mui/material"
import { MdSearch } from "@react-icons/all-files/md/MdSearch"
import { setFromEvent } from "lib/setFromEvent"
import { SearchFor } from "event-definitions"

export function SearchBox() {
    const [search, setSearch] = useState("")
    const ref = useRef()
    const isMobile = useMobile()
    useEffect(() => {
        setTimeout(() => {
            ref.current?.focus()
        }, 100)
    }, [])
    return (
        <Stack spacing={2} sx={{ width: 1, my: 4 }}>
            <TextField
                ref={ref}
                variant="outlined"
                autoFocus
                name="search"
                autoComplete="new-password"
                onKeyUp={(e) => e.key === "Enter" && go()}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button onClick={go} size="small" sx={{ fontSize: 24 }} color="primary" variant="contained">
                                <MdSearch />
                            </Button>
                        </InputAdornment>
                    ),
                }}
                placeholder={isMobile ? "Search..." : "Search for words, or a Uniclass, SFG20 or NRM code..."}
                value={search}
                onChange={setFromEvent(setSearch)}
                fullWidth
            />
        </Stack>
    )

    function go() {
        SearchFor.raise(search)
    }
}
