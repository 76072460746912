import { Button } from "@mui/material"
import { MdDownload } from "@react-icons/all-files/md/MdDownload"
import { downloadTasksAsExcelSpreadsheet } from "routes/regime/plugins/download-tasks/controller/download-tasks-as-excel-spreadsheet"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useState } from "react"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { successSnackbar } from "lib/snackbar/success-snackbar"
import Iconify from "minimals-template/components/Iconify"

export function DownloadTasksAsExcelSpreadsheet() {
    const [downloading, setDownloading] = useState(false)
    const { shareLink } = useBoundContext()
    console.log({ shareLink })
    return (
        !!shareLink.canDownloadExcel && (
            <Button
                fullWidth
                data-cy="download-excel-button"
                disabled={downloading}
                size="small"
                endIcon={downloading && <Iconify icon="ic:round-hourglass-empty" className="iconSpin" />}
                startIcon={<MdDownload />}
                sx={{ alignSelf: "center" }}
                onClick={download}
            >
                Download Tasks (Excel)
            </Button>
        )
    )

    async function download() {
        try {
            setDownloading(true)
            const result = await downloadTasksAsExcelSpreadsheet(shareLink._id)
            if (result) {
                const a = document.createElement("a")
                a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result}`
                a.download = `${shareLink.name}-tasks.xlsx`
                a.click()
                successSnackbar(`${shareLink.name}-tasks.xlsx created`)
            } else {
                errorSnackbar("Could not find the tasks to download")
            }
        } catch (e) {
            errorSnackbar("Failed to download tasks.")
            console.error("Failed to download", e)
        } finally {
            setDownloading(false)
        }
    }
}
