import { Box, ButtonBase, Card, Typography } from "@mui/material"
import uniqolor from "uniqolor"

export function SpaceTile({ space, onClick }) {
    return (
        <ButtonBase
            color="primary"
            sx={{ flex: 1, width: 1, height: 1, display: "flex", flexDirection: "column" }}
            aria-label={`Choose ${space.name}`}
            onClick={onClick}
        >
            <Card
                sx={{
                    width: 1,
                    textAlign: "center",
                    cursor: "pointer",
                    flex: 1,
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    pb: 1,
                }}
            >
                <Box width={1} sx={{ position: "relative" }}>
                    <Box
                        width={1}
                        component="img"
                        border="none"
                        sx={{ objectFit: "cover" }}
                        src={
                            space.image ?? "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        }
                        bgcolor={
                            uniqolor(space.name, {
                                saturation: [75, 99],
                                lightness: [50, 80],
                            }).color
                        }
                        height={180}
                        color="#333"
                        overflow="hidden"
                    />
                </Box>
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                    {space.name ?? "Not named"}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {space.location ?? ""}
                </Typography>
            </Card>
        </ButtonBase>
    )
}
