import { Box, FormControlLabel, Switch } from "@mui/material"
import { useBoundValue } from "../binding/use-bound-value"

export function ToggleDenseSwitch({ dense }) {
    const [isDense, setIsDense] = useBoundValue("isDense", dense)

    return (
        <>
            <FormControlLabel
                control={<Switch onChange={handleChange} checked={isDense} />}
                label="Dense"
                sx={{ pb: 1.75 }}
            />
            <Box flex={1} />
        </>
    )
    function handleChange() {
        setIsDense((p) => !p)
    }
}
