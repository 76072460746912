import { checkPermission } from "library/authorization"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { useNavigate } from "react-router"
import { ForbiddenIllustration } from "minimals-template/assets"
import React from "react"
import { PermissionDeniedUI } from "./PermissionDeniedUI"

/**
 * `DemandGuard` is a component that renders its children only if the specified permission demands are met.
 * It can check for either all or some of the given demands based on the `and` parameter.
 *
 * @param {boolean} [hasContent=true] - Determines if the component should render its content.
 * @param {React.ReactNode} children - The child components to render if the demands are met.
 * @param {string[]|string} demands - A demand or an array of demand strings representing the required permissions.
 * @param {boolean} [and=false] - If true, all demands must be met (AND logic). If false, any one of the demands is
 *     sufficient (OR logic).
 * @param {string} [message="You do not have permission to access this page"] - The message to display when the demands
 *     are not met.
 * @param {string} [warning="Permission Denied"] - The warning title to display when the demands are not met.
 * @param {React.ComponentType} [Illustration=ForbiddenIllustration] - The illustration component to display when the
 *     demands are not met.
 * @param {boolean} [redirect=false] - If true, component will show errorSnackbar and navigate to homepage. If false,
 *     the errorSnackbar won't show and user will remain on page.
 *
 * @returns {React.ReactNode} - Returns the child components if demands are met, otherwise returns a message and
 *     illustration based on the provided props.
 *
 * @example
 * To use the component to guard content that requires both 'admin' and 'editor' permissions:
 * <DemandGuard demands={['admin', 'editor']} and >
 *     <YourComponent />
 * </DemandGuard>
 *
 * @example
 * To use the component where either 'admin' or 'editor' permission is sufficient:
 * <DemandGuard demands={['admin', 'editor']} >
 *     <YourComponent />
 * </DemandGuard>
 */

export function DemandGuard({
    and = false,
    hasContent = true,
    children,
    demands,
    message = "You do not have permission to access this page",
    redirect = true,
    warning = "Permission Denied",
    Illustration = ForbiddenIllustration,
}) {
    const navigate = useNavigate()
    const permissionGranted = checkPermission(demands, and)

    if (permissionGranted) {
        return <>{children}</>
    }

    if (redirect) {
        errorSnackbar(message, { onClose: () => navigate("/app") })
    }

    if (!hasContent) {
        return false
    }

    return <PermissionDeniedUI message={message} warning={warning} Illustration={Illustration} />
}

export default DemandGuard
