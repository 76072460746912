import { useEffect, useMemo, useState } from "react"
import { Autocomplete, MenuItem, TextField } from "@mui/material"
import { setFromEvent } from "lib/setFromEvent"
import stringSimilarity from "string-similarity"
import { OpenSchedule } from "event-definitions"
import { parentTreeItem } from "library/tree"
import { useDebouncedValue } from "lib/@hooks/useDebouncedValue"
import { useTreeItemIndex } from "routes/schedule/lib/useTreeItemIndex"
import { SCHEDULE } from "library/constants"
import { isIdInGroup } from "library/id-helpers"
import Iconify from "minimals-template/components/Iconify"
import { errorSnackbar } from "lib/snackbar/error-snackbar"

function checkForUnlicensed(c) {
    if (!c.children) return c.notLicensed
    return c.children.some((c) => checkForUnlicensed(c))
}

export function SearchSchedules() {
    const [currentOptions, setCurrentOptions] = useState([])
    const [value, setValue, current] = useDebouncedValue("", 600)
    const treeIndex = useTreeItemIndex(false)
    const searchable = useMemo(
        () =>
            Object.values(treeIndex)
                .filter(
                    (c) =>
                        !!c.$ &&
                        !!c.label &&
                        typeof c.label === "string" &&
                        !c.isAlias &&
                        !c.folderHandling &&
                        c[parentTreeItem].label !== "Retired"
                )
                .unique("label"),
        [treeIndex]
    )

    useEffect(() => {
        if (!current.trim()) {
            setCurrentOptions([])
            setOpen(false)
            return
        }
        if (isIdInGroup(current)) {
            setOpen(false)
            setCurrentOptions([])
            return
        }
        const lowerCase = current?.toLowerCase()
        const list = [
            ...searchable.map((c) => ({
                label: c.label,
                code: c.code,
                type: c.hasChildren ? "folder" : "schedule",
                notLicensed: checkForUnlicensed(c),
                value: c.id,
                item: c,
                d: c.label.toLowerCase().startsWith(lowerCase)
                    ? 0.9
                    : stringSimilarity.compareTwoStrings(lowerCase, c.label.toLowerCase()),
            })),
            ...searchable.map((c) => ({
                label: c.label,
                code: c.code,
                value: c.id,
                type: c.hasChildren ? "folder" : "schedule",
                notLicensed: checkForUnlicensed(c),
                item: c,
                d: c.code?.toLowerCase().startsWith(lowerCase)
                    ? 0.9
                    : stringSimilarity.compareTwoStrings(lowerCase, c.code?.toLowerCase() ?? ""),
            })),
        ]
            .sortBy("d", true)
            .unique("value")
        setCurrentOptions(list.filter((f) => f.d > 0.2))
        setOpen(true)
    }, [current, searchable])
    const [open, setOpen] = useState(false)
    return (
        <Autocomplete
            sx={{ my: 2 }}
            open={open && !!value.length}
            componentsProps={{ popper: { sx: { zIndex: 6000 } } }}
            filterOptions={(o) => o}
            renderOption={(props, option) => (
                <MenuItem
                    key={option.value}
                    onClick={() => {
                        if (option.notLicensed && option.type !== "folder") {
                            errorSnackbar("You have not licensed that schedule!")
                            return
                        }

                        select(option).catch(console.error)
                        setOpen(false)
                    }}
                    sx={{ color: option.notLicensed ? "unlicensed.dark" : undefined }}
                >
                    {option.type === "folder" ? (
                        <Iconify
                            sx={{
                                display: "inline-block",
                                mr: 1,
                                color: option.notLicensed ? "unlicensed.main" : "primary.main",
                            }}
                            icon="material-symbols:folder-rounded"
                        />
                    ) : (
                        <Iconify
                            sx={{
                                display: "inline-block",
                                mr: 1,
                                color: option.notLicensed ? "unlicensed.main" : "primary.main",
                            }}
                            icon="mdi:file-document-outline"
                        />
                    )}{" "}
                    {option.code ? `${option.code} - ` : ""} {option.label}
                </MenuItem>
            )}
            renderInput={(props) => (
                <TextField
                    autoFocus
                    className="search-field"
                    inputProps={{ className: "search-input" }}
                    onChange={setFromEvent(gotValue)}
                    placeholder="Search..."
                    variant="standard"
                    {...props}
                    value={isIdInGroup(value) ? "" : value}
                />
            )}
            options={currentOptions}
        />
    )

    function gotValue(v) {
        setValue(v)
    }

    async function select(option) {
        setValue("")
        setOpen(false)
        setCurrentOptions([])
        if (option?.item?.$?.type === SCHEDULE) {
            await OpenSchedule(option.item[parentTreeItem].id).raiseAsync({
                ...option.item[parentTreeItem],
                type: "folder",
            })
        }
        if (option?.value && option?.item) {
            await OpenSchedule(option.value).raiseAsync(option.item)
        }
    }
}
