import { Alert, Box, Typography } from "@mui/material"
import GenericSearch from "lib/@components/paging/generic-search"
import { UserAvatar } from "minimals-template/components/user-avatar"
import { AccessFilter } from "routes/regime/plugins/regime-access/components/access-filter"

export function RegimeAccessTableHead({ searchProps, permittedUsers }) {
    return (
        <Box pb={1}>
            <Box sx={{ display: "flex", p: 3 }}>
                <Box sx={{ width: "70%" }}>
                    <Box>
                        <GenericSearch sx={{ p: 1 }} showResultCount={false} data-cy="searchbar" {...searchProps} />
                    </Box>
                </Box>
                <Box sx={{ width: "30%" }}>
                    <AccessFilter data-cy="access-filter" />
                </Box>
            </Box>
            <Box display="flex" alignItems="center" pl={3} pb={2}>
                <Typography variant="subtitle2">The following people have access : </Typography>
                <Box display="flex" alignItems="center" pl={1}>
                    {permittedUsers?.map((user, idx) => (
                        <UserAvatar key={idx} sx={{ mr: 1 }} user={user} />
                    ))}
                </Box>
            </Box>
            <Box pl={3} pb={2}>
                <Alert severity="info">
                    <Typography variant="subtitle2">
                        User roles are set within admin settings, speak to your administrator if you wish for a change
                        to be made.
                    </Typography>
                </Alert>
            </Box>
        </Box>
    )
}
