import { isEnabled } from "lib/@components/feature"
import { registerTab } from "lib/@components/tabs"
import { registerFeature } from "lib/features"
import { Lazy } from "lib/make-lazy"

export const FEATURE_FLAG_CLIENT_BACKUP = "clientBackup"
registerFeature("Client Backup", FEATURE_FLAG_CLIENT_BACKUP)

registerTab({
    tab: "clientAdmin",
    id: "backups",
    title: "Client Backup",
    predicate() {
        return isEnabled(FEATURE_FLAG_CLIENT_BACKUP)
    },
    content: <Lazy priority={10000} importer={() => import("./DatabaseBackup")} extract="DatabaseBackup" />,
})
