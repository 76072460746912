import { ContextMenuItem } from "lib/@components/context-menu-bar/context-menu-item"
import { confirm } from "lib/@dialogs/confirm"
import { busyWhile } from "lib/@components/busy"
import { gql } from "@apollo/client"
import { convertFolderName } from "routes/schedule/folder-manager/folder-management"
import { parentTreeItem } from "library/tree"
import { waitForScheduleRefresh } from "routes/schedule/lib/wait-for-schedule-refresh"
import { clearDialogs } from "lib/@dialogs/dialogOpen"
import { getTreeIdFromItem } from "lib/get-tree-id-from-item"
import { mutate } from "lib/graphql/mutate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function RemoveFolder() {
    const { parentItem } = useBoundContext()
    return (
        <ContextMenuItem
            divider={true}
            icon="material-symbols:delete"
            label={`Remove "${parentItem.label}"`}
            onClick={removeFolder}
        />
    )

    async function removeFolder() {
        if (await confirm("Are you sure?", "Remove")) {
            clearDialogs()
            if (window.location.href.includes(parentItem.id)) {
                window.history.back()
            }
            await busyWhile(
                async () =>
                    mutate(
                        gql`
                            mutation removeGroup($tree: String!, $group: String!, $ids: [String]!) {
                                removeReferencesFromGroup(tree: $tree, group: $group, ids: $ids)
                            }
                        `,
                        {
                            tree: getTreeIdFromItem(parentItem),
                            group: convertFolderName(parentItem[parentTreeItem].id),
                            ids: [parentItem.id],
                        }
                    ),
                "Removing Group",
                waitForScheduleRefresh
            )
        }
    }
}
