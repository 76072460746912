import React, { useEffect, useRef, useState } from "react"
import { Box, List, ListItem, useTheme } from "@mui/material"
import { BusyAdd, BusyRemoved, Freezer } from "event-definitions"
import { getLastRenderTime, getLastStack } from "lib/render-trap"
import { generate } from "library/guid"
import { RealLoadingScreen } from "minimals-template/components/LoadingScreen"

const DEBUG_BUSY = false
// This is to self terminate the busy, we think we may have a race on BUSY/REMOVED in the tests.
const CONFIGURABLE_BUSY_TIMEOUT = process.env.CONFIGURABLE_BUSY_TIMEOUT || 10000

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false, error: null, errorInfo: null }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo })
    }

    render() {
        if (this.state.hasError) {
            if (window.Cypress) {
                return (
                    <div>
                        <h1>Something went wrong.</h1>
                        <details style={{ whiteSpace: "pre-wrap" }}>
                            {this.state.error?.toString()}
                            <br />
                            {this.state.errorInfo?.componentStack}
                        </details>
                    </div>
                )
            }
        }
        return this.props.children
    }
}

export function BusyComponent() {
    const theme = useTheme()
    const showTimeout = useRef(0)
    const [busyList, setBusy] = useState({})
    const terminationTimeout = useRef(null)
    const [shouldShow, setShouldShow] = useState(false)

    useEffect(() => {
        if (window.Cypress) {
            if (shouldShow) {
                clearTimeout(terminationTimeout.current)
                terminationTimeout.current = setTimeout(() => {
                    console.log(">>>>>Terminating busy")
                    console.log(busyList)
                    setBusy({})
                    hide()
                }, CONFIGURABLE_BUSY_TIMEOUT)
            }
        }
        return () => {
            if (window.Cypress) {
                clearTimeout(terminationTimeout.current)
                clearTimeout(showTimeout.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldShow])

    window.showBusy = () => console.log(busyList)
    window.notBusy = () => {
        setBusy({})
        hide()
    }
    BusyAdd.useEvent(
        ({ id, description, opacity = 0.24 }) => {
            clearTimeout(showTimeout.current)
            Freezer.raise(true)
            setShouldShow(true)
            setBusy((busy) => ({ ...busy, [id]: DEBUG_BUSY ? getLastStack() : description ?? " ", opacity }))
        },
        [setBusy]
    )
    BusyRemoved.useEvent(
        ({ id }) => {
            setBusy((busy) => {
                const newBusy = { ...busy }
                delete newBusy[id]
                if (Object.keys(newBusy).length === 0) {
                    showTimeout.current = setTimeout(hide, 50)
                }
                return newBusy
            })
        },
        [setBusy]
    )
    const { opacity } = busyList
    delete busyList.opacity

    return (
        <Box
            id="busy"
            sx={{
                position: "fixed",
                left: 0,
                top: 0,
                right: 0,
                width: "100vw",
                height: "100vh",
                zIndex: 5000,
                pointerEvents: shouldShow ? "all" : "none",
                flexDirection: "column",
                opacity: shouldShow ? 1 : 0,
                transition: "opacity linear 0.2s, backdrop-filter linear 0.2s, background-color linear 0.2s",
                backdropFilter: shouldShow ? `blur(6px)` : "blur(0px)",
                backgroundColor: shouldShow
                    ? `${theme.palette.background.default}${theme.palette.background.default.length < 5 ? "b" : "b0"}`
                    : undefined,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {shouldShow && (
                <RealLoadingScreen opacity={opacity}>
                    <List>
                        {Object.values(busyList).map((child, index) => (
                            <ListItem key={index}>{child}</ListItem>
                        ))}
                    </List>
                </RealLoadingScreen>
            )}
        </Box>
    )

    function hide() {
        if (Date.now() - getLastRenderTime() < 200) {
            if (DEBUG_BUSY) {
                console.log(getLastStack())
            }
            showTimeout.current = setTimeout(hide, 50)
        } else {
            Freezer.raiseLater(false)
            setShouldShow(false)
        }
    }
}

export const Busy = () => (
    <ErrorBoundary>
        <BusyComponent />
    </ErrorBoundary>
)

export function addTempBusy() {
    const id = generate()
    BusyAdd.raise({ id })
    setTimeout(() => BusyRemoved.raise({ id }), 25)
}

export function useBusy(isBusy, description = "", delay = 0) {
    const [id] = useState(generate)
    const timer = useRef()
    isBusy = !!isBusy
    useEffect(() => {
        clearTimeout(timer.current)
        if (isBusy) {
            timer.current = setTimeout(() => {
                BusyAdd.raise({ id, description })
            }, delay)
        } else {
            BusyRemoved.raise({ id })
        }
        return () => {
            clearTimeout(timer.current)
            return BusyRemoved.raise({ id })
        }
    }, [isBusy, id, description, delay])
}

export async function busyWhile(fn, description, extra = () => true) {
    const id = generate()
    try {
        BusyAdd.raise({ id, description })

        const result = await (fn.then ? fn : fn())
        // noinspection ES6RedundantAwait
        await extra()
        return result
    } catch (error) {
        console.error("Error in busyWhile:", error)
        throw error
    } finally {
        BusyRemoved.raise({ id })
    }
}

export async function busyWhileWithDelay(fn, delay = 300, description = undefined, extra = () => true) {
    const id = generate()
    let timer
    try {
        timer = setTimeout(() => {
            BusyAdd.raise({ id, description })
        }, delay)

        const result = await (fn.then ? fn : fn())
        // noinspection ES6RedundantAwait
        await extra()
        return result
    } catch (error) {
        console.error("Error in busyWhileWithDelay:", error)
        throw error
    } finally {
        clearTimeout(timer)
        BusyRemoved.raise({ id })
    }
}

export function makeBusyFunction(fn, description, extra = () => true, delay = 250) {
    return async function run(...params) {
        const id = generate()
        let timer
        try {
            timer = setTimeout(() => {
                BusyAdd.raise({ id, description })
            }, delay)
            const result = await fn(...params)
            // noinspection ES6RedundantAwait
            await extra()
            return result
        } catch (error) {
            console.error("Error in makeBusyFunction:", error)
            throw error
        } finally {
            clearTimeout(timer)
            BusyRemoved.raise({ id })
        }
    }
}
