import { Box, Grid, Stack } from "@mui/material"
import { FilterSchedule, RefreshScheduleSelector, TreeRootsUpdated } from "event-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { Repeat } from "lib/@components/repeat"
import Iconify from "minimals-template/components/Iconify"
import AppWelcome from "minimals-template/pages/sections/@dashboard/general/app/AppWelcome"
import { HelpTag, HELPTAG_MAINTENANCE_REGIME } from "routes/help/helpTag"
import { FilterView } from "routes/schedule/plugins/filter/runtime-main"
import { ScheduleSelectorList, ScheduleSelectorPanel, TreeToolbar } from "slot-definitions"
import { RegimeItem } from "routes/regime/regime-management/regime-item"
import { replaceAllWith } from "lib/@components/slot/replace-all-with"
import { Frag } from "lib/@components/slot/frag"
import { EDIT_TREE } from "library/constants"
import { AddRegime } from "./add-regime"
import { busyWhile } from "lib/@components/busy"
import { useDemand } from "lib/authorization/use-demand"
import { useEffect, useState } from "react"
import LoadingScreen from "minimals-template/components/LoadingScreen"

import { MULTIPLE_REGIMES_DEMAND } from "routes/schedule/folder-manager/multiple-regimes-demand"
import { navigate } from "lib/routes/navigate"
import { createTree } from "routes/schedule/tree/schedules/controller/create-tree"
import { RegimeCountIndicator } from "./regime-count-indicator"

ScheduleSelectorPanel(EDIT_TREE).plug(<ManageRegime />)
TreeToolbar(EDIT_TREE).plug(replaceAllWith(<Frag />, 2000))
ScheduleSelectorList(EDIT_TREE).plug(replaceAllWith(<Regimes />))
ScheduleSelectorList(EDIT_TREE).plug(<HelpTag tags={[HELPTAG_MAINTENANCE_REGIME]} />)

function Regimes() {
    const [show, setShow] = useState(false)
    const multipleRegimes = useDemand(MULTIPLE_REGIMES_DEMAND)

    const { parentItem } = useBoundContext()
    useEffect(() => {
        if (parentItem) {
            if (!multipleRegimes) {
                if (parentItem?.children.length) {
                    navigate(`/app/schedules?id=${parentItem.children[0].id}`, { replace: true })
                } else {
                    setTimeout(async () => {
                        const newTree = await busyWhile(
                            async () => createTree("Regime"),
                            "Creating regime",
                            () => TreeRootsUpdated.wait(10000)
                        )
                        navigate(`/app/schedules?id=${newTree}`, { replace: true })
                    })
                }
            }
            setShow(true)
        }
    }, [multipleRegimes, parentItem])
    RefreshScheduleSelector.useRefresh()

    return !!show && multipleRegimes ? (
        <Stack spacing={2} sx={{ pr: 2 }}>
            <FilterView sx={{ width: 1 }} />
            <AddRegime />
            <Grid container spacing={2} alignItems="stretch" sx={{ pr: 4 }}>
                <RegimeCountIndicator />
                <Repeat list={FilterSchedule.call({ items: parentItem.children })?.items} item={<RegimeItem />} />
            </Grid>
        </Stack>
    ) : (
        <LoadingScreen />
    )
}

function ManageRegime() {
    const multipleRegimes = useDemand(MULTIPLE_REGIMES_DEMAND)
    return (
        !!multipleRegimes && (
            <AppWelcome
                sx={{ mb: 2 }}
                title="Maintenance Regimes"
                img={
                    <Box position="relative" overflow="hidden" flexGrow={1} width={150} height={160}>
                        <Box position="absolute" right={20} zIndex={200} opacity={0.5}>
                            <Iconify
                                sx={{
                                    width: 90,
                                    height: 90,
                                    opacity: 0.3,
                                    mr: 3,
                                    transform: "rotate(15deg) scale(1.7) translateY(25px)",
                                    color: "primary.dark",
                                }}
                                icon="ph:buildings-fill"
                            />
                        </Box>
                    </Box>
                }
                description={
                    <>
                        <Box>
                            A Maintenance Regime allows you to organise schedules and make customisations to them
                            differently for each group. You can share and export the contents of a Maintenance Regime.
                        </Box>
                        <Box mt={1}>
                            You can have more than one regime and each one can be reviewed before publishing it to live.
                        </Box>
                    </>
                }
            />
        )
    )
}
