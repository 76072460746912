export const relevantDemands = ["accessAllRegimes", "manageAccess", "publishRegime", "canMakeShare", "manageRegime"]

export const relevantIcons = {
    accessAllRegimes: "ic:round-admin-panel-settings",
    manageAccess: "ic:round-manage-accounts",
    publishRegime: "ic:round-manage-search",
    canMakeShare: "ic:round-share",
    manageRegime: "ic:round-edit",
}

export const RegimeAccessTableRowHeaders = [
    { id: "displayName", label: "Name", align: "left" },
    { id: "jobTitle", label: "Job Title", align: "left" },
    { id: "userRoles", label: "User Roles", align: "left", canOrderBy: false },
    { id: "access", label: "Access", align: "left", canOrderBy: false },
]

export const filterStates = {
    All: { label: "All", value: "" },
    Enabled: { label: "Enabled", value: "enabled" },
    Disabled: { label: "Disabled", value: "disabled" },
}
