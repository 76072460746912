import { Box, ListItemButton } from "@mui/material"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { FolderIcon } from "routes/schedule/tree/schedules/folder-icon"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function BasicFolder({ icon, color, caption, children, last, Component = ListItemButton }) {
    const { onClick, noDivider } = useBoundContext()
    last = last || noDivider
    return (
        <Component divider={!last} onClick={onClick}>
            <ListItemBox spacing={1}>
                <FolderItemPart />
                <FolderItemPart>
                    <FolderIcon sx={{ color }} icon={icon} />
                </FolderItemPart>
                <Box flex={1}>
                    {caption}
                    {children}
                </Box>
            </ListItemBox>
        </Component>
    )
}
