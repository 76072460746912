import { NotOnMobile } from "lib/@components/mobile"
import { AuditLog } from "./audit-log"
import { AuditTopBar } from "./top-bar"
import { getAuditsByReferenceIdAndType } from "routes/audit/controller/get-audits-by-reference-id"
import { Bound } from "lib/@components/binding/Bound"

export function AuditContainer({ setOpen, type, id }) {
    const data = getAuditsByReferenceIdAndType.useResults(id, type) ?? []

    return (
        <Bound data={data} auditId={id} type={type}>
            <NotOnMobile>
                <AuditTopBar setOpen={setOpen} />
            </NotOnMobile>
            <AuditLog />
        </Bound>
    )
}
