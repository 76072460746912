import createAvatar from "minimals-template/components/utils/createAvatar"
import { Tooltip, Typography } from "@mui/material"
import Avatar from "minimals-template/components/Avatar"

export function UserAvatar({ user, ...other }) {
    const avatar = createAvatar(user?.displayName ?? user?.metadata?.displayName ?? "Unknown Name")
    return (
        !!user &&
        !user.__NO_USER && (
            <Tooltip title={user?.displayName ?? user?.metadata?.displayName ?? "Unknown User"}>
                <Avatar
                    src={user?.photoURL ?? user?.metadata?.photoURL}
                    alt={user?.displayName ?? user?.metadata?.displayName}
                    color="primary"
                    {...other}
                    sx={{
                        ...other.sx,
                        color: "#333",
                        backgroundColor: avatar.color,
                    }}
                >
                    <Typography variant="body1">{avatar.name}</Typography>
                </Avatar>
            </Tooltip>
        )
    )
}
