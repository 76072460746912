import { useLayoutEffect, useMemo, useRef, useState } from "react"
import { CaptureNow, Freezer, Navigate } from "event-definitions"
import { Box } from "@mui/material"
import { ErrorBoundary } from "ErrorBoundary"
import Router from "routes"
import { useInterval } from "lib/@hooks/useInterval"
import { getLastRenderTime } from "lib/render-trap"

window.noFreeze = !!window.Cypress

export function captureNow(fn) {
    return (...params) =>
        // CaptureNow.raise()
        fn(...params)
}

let lastCapture = 0
let captureHref = ""

Navigate.before.handleOnce(() => {
    CaptureNow.raise()
})

export function FrozenRouter() {
    const ref = useRef()
    const dontScroll = useRef()
    const fns = useRef([])
    const frozen = useRef(false)
    const content = useRef(null)
    const [freeze, setFreeze] = useState(false)
    let lastTime = 0
    frozen.current = freeze
    Freezer.useEvent(doFreeze)
    CaptureNow.useEvent(() => {
        if (window.noFreeze) return
        if (Date.now() - lastCapture < 100) return
        lastCapture = Date.now()
        captureHref = window.location.href
        if (!frozen.current) {
            content.current = clone(ref.current)
            const scrolled = recurseFindScroll(ref.current)
            fns.current = []
            if (content.current) {
                scrolled.forEach((node) => {
                    const parentPath = Array.from(node.parentElement.classList).join(".")
                    const myClasses = Array.from(node.classList).join(".")
                    const { scrollTop } = node

                    fns.current.push(() => {
                        const target = content.current.querySelector(`.${parentPath} > .${myClasses}`)
                        if (target) {
                            target.scrollTop = scrollTop
                        }
                    })
                })
            }
            dontScroll.current = true
            setTimeout(() => (dontScroll.current = false), 100)
        }
    })
    useInterval(
        () => {
            if (ref.current) {
                if (window.noFreeze) return
                if (!frozen.current && !dontScroll.current) {
                    if (lastTime !== getLastRenderTime()) {
                        lastTime = getLastRenderTime()
                        content.current = clone(ref.current)
                    }
                }
            }
        },
        200,
        []
    )
    const routes = useMemo(
        () => (
            <ErrorBoundary>
                <Router key="routes" />
            </ErrorBoundary>
        ),
        []
    )
    return (
        <Box position="relative">
            <Box ref={ref}>{routes}</Box>
            {!window.noFreeze && freeze && !!content.current && captureHref !== window.location.href && (
                // <Box sx={{ ml: 0 }} dangerouslySetInnerHTML={{ __html: content.current }} />
                <Cloned insert={content.current} fns={fns.current} />
            )}
        </Box>
    )

    function doFreeze(freeze) {
        if (freeze) {
            if (!dontScroll.current) {
                const scrolled = recurseFindScroll(ref.current)
                fns.current = []
                if (content.current) {
                    scrolled.forEach((node) => {
                        const { scrollTop, scrollLeft } = node
                        const parentPath = Array.from(node.parentElement.classList).join(".")
                        const myClasses = Array.from(node.classList).join(".")
                        fns.current.push(() => {
                            const target = content.current.querySelector(`.${parentPath} > .${myClasses}`)
                            if (target) {
                                target.scrollTop = scrollTop
                                target.scrollLeft = scrollLeft
                            }
                        })
                    })
                }
            }
            setFreeze(freeze)
        } else {
            setFreeze(false)
        }
    }
}

function recurseFindScroll(node, output = []) {
    if (!node) {
        return output
    }
    if (node.scrollTop || node.scrollLeft) {
        output.push(node)
    }
    node.childNodes.forEach((n) => recurseFindScroll(n, output))
    return output
}

function clone(node) {
    return node?.cloneNode(true)
}

function Cloned({ insert, fns = [] }) {
    const ref = useRef()
    useLayoutEffect(() => {
        ref.current.appendChild(insert)
        fns.forEach((f) => f())
    }, [fns, insert])
    return (
        <Box
            bgcolor="background.default"
            ref={ref}
            position="absolute"
            sx={{ zIndex: 1000 }}
            left={0}
            right={0}
            top={0}
            bottom={0}
        />
    )
}
