import { Avatar, Box, ButtonBase, Card, Typography, useTheme } from "@mui/material"
import SvgIconStyle from "minimals-template/components/SvgIconStyle"
import uniqolor from "uniqolor"
import { FolderIcon } from "routes/schedule/tree/schedules/folder-icon"

export function GroupTile({ parentItem, onClick }) {
    const theme = useTheme()
    return (
        <ButtonBase
            color="primary"
            sx={{ flex: 1, display: "flex", flexDirection: "column" }}
            aria-label={`Choose ${parentItem.name}`}
            onClick={onClick}
        >
            <Card
                sx={{
                    textAlign: "center",
                    cursor: "pointer",
                    width: 1,
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    pb: 1,
                }}
            >
                <Box sx={{ position: "relative", width: 1 }}>
                    <Box
                        minHeight={150}
                        overflow="hidden"
                        position="relative"
                        width={"100vw"}
                        bgcolor={
                            uniqolor(parentItem.label, {
                                saturation: [55, 99],
                                lightness: [70, 90],
                            }).color
                        }
                    />
                    <>
                        <SvgIconStyle
                            src="https://minimal-assets-api-dev.vercel.app/assets/icons/shape-avatar.svg"
                            sx={{
                                width: 144,
                                height: 62,
                                zIndex: 10,
                                left: 0,
                                right: 0,
                                bottom: -6,
                                mx: "auto",
                                position: "absolute",
                                color: "background.paper",
                            }}
                        />
                        <Box
                            sx={{
                                height: 24,
                                backgroundColor: theme.palette.background.paper,
                                position: "absolute",
                                left: 0,
                                right: 0,
                                width: "100%",
                                bottom: -4,
                                zIndex: 10,
                            }}
                        />
                        <Avatar
                            alt={`${parentItem.label} picture`}
                            src={parentItem.data?.image}
                            sx={{
                                width: 72,
                                height: 72,
                                zIndex: 11,
                                left: 0,
                                right: 0,
                                bottom: -22,
                                mx: "auto",
                                position: "absolute",
                            }}
                        >
                            <FolderIcon />
                        </Avatar>
                    </>
                </Box>
                <Typography variant="subtitle1" sx={{ mt: 5 }}>
                    {parentItem.label ?? "Not named"}
                </Typography>
            </Card>
        </ButtonBase>
    )
}
