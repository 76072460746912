import { Box, Button, ListItemButton, Typography } from "@mui/material"
import { PropertyBox } from "lib/@components/property-box"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { Selected } from "routes/schedule/components/selected"
import Iconify from "minimals-template/components/Iconify"
import { PublishedItemMain, ScheduleItemBeneath, ScheduleItemCode } from "slot-definitions"
import { DateDisplay } from "lib/@components/dateDisplay"
import { OpenSchedule } from "event-definitions"
import { uniqueId } from "library/database/split-id"
import { busyWhile } from "lib/@components/busy"
import { retireSchedules } from "routes/schedule/my-schedules/my-custom-schedules/controller/retire-schedules"
import { waitForScheduleRefresh } from "routes/schedule/lib/wait-for-schedule-refresh"
import { BlockClicks } from "lib/@components/block-clicks"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { TruncatedTypography } from "lib/@components/truncated-typography"

export function RetiredItem() {
    const { target } = useBoundContext()
    return (
        <ListItemButton onClick={edit} className="retired-item">
            <PropertyBox spacing={0}>
                <ListItemBox spacing={1}>
                    <FolderItemPart>
                        <Selected />
                    </FolderItemPart>
                    <FolderItemPart>
                        <Iconify
                            icon="mdi:file-document-outline"
                            sx={{ width: 32, height: 32, color: "primary.dark" }}
                        />
                    </FolderItemPart>
                    <PublishedItemMain.Slot schedule={target}>
                        <Box display="flex" alignItems="flex-end" flex={1}>
                            <TruncatedTypography>{target.title || target._id}</TruncatedTypography>
                            <Typography
                                component="div"
                                variant="caption"
                                sx={{ color: "text.secondary", ml: 0.5, mb: 0.1 }}
                            >
                                v{target.version}
                            </Typography>
                            <Typography
                                variant="caption"
                                component="div"
                                sx={{ ml: 0.5, mb: 0.1, color: "text.secondary", opacity: 0.5 }}
                            >
                                <DateDisplay
                                    modify={(t) =>
                                        `(${t.replace(/\s+minute(s*)/gi, "m").replace(/\s+second(s*)/gi, "s")})`
                                    }
                                    maxTime={1000 * 60 * 60}
                                    date={target.modified}
                                />
                            </Typography>
                        </Box>
                    </PublishedItemMain.Slot>

                    <Box textAlign="right" display="flex" alignItems="center">
                        <ScheduleItemCode.Slot schedule={target}>
                            <Typography component="div" variant="body2">
                                {target.code}
                            </Typography>
                        </ScheduleItemCode.Slot>
                    </Box>
                    <Box ml={1}>
                        <BlockClicks>
                            <Button onClick={reinstate} variant="contained">
                                Reinstate
                            </Button>
                        </BlockClicks>
                    </Box>
                </ListItemBox>
                <BlockClicks>
                    <ScheduleItemBeneath.Slot schedule={target} />
                </BlockClicks>
            </PropertyBox>
        </ListItemButton>
    )

    async function reinstate() {
        await busyWhile(() => retireSchedules(false, [target._id]), "Reinstating", waitForScheduleRefresh)
    }

    function edit() {
        OpenSchedule(target._id).raiseAsync({ id: `allPublished!${uniqueId(target._id)}` })
    }
}
