import { gql } from "@apollo/client"
import { EditEnding, UnlockDraft } from "event-definitions"
import { beep } from "lib/beep"
import { Bound } from "lib/@components/binding/Bound"
import { debounce } from "lib/debounce"
import { delay } from "lib/delay"
import { Loader } from "lib/@components/Loader"
import noop from "lib/noop"
import { useUndo } from "lib/undo"
import { useRefresh } from "lib/@hooks/useRefresh"
import { initialize } from "library/behaviours"
import { uniqueId } from "library/database/split-id"
import { raiseOnce } from "library/local-events"
import { useCallback, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { states } from "routes/schedule/edit/schedule-edit.runtime"
import { getScheduleRecordId } from "routes/schedule/lib/get-schedule-record-id"
import { useScheduleForEditing } from "routes/schedule/lib/use-schedule-for-editing"
import { errorSnackbar } from "lib/snackbar/error-snackbar"
import { mutate } from "lib/graphql/mutate"
import ScheduleDisplay from "routes/schedule/scheduleDisplay"
import { SaveIndicator, ToBeSaved } from "lib/@components/save-indicator/save-indicator"
import { Lock } from "minimals-template/components/lock/Lock"
import { stillEditing } from "minimals-template/components/lock/controller/still-editing"
import { ErrorPage } from "lib/@components/error-page"

export function ScheduleEdit(config) {
    const { id } = useParams()
    const code = getScheduleRecordId(id)
    const definition = useScheduleForEditing(code, true)
    const loading = !definition
    const error = definition instanceof Error ? definition : undefined

    useEffect(() => {
        raiseOnce("CurrentSchedule", id)
    }, [id])

    return loading ? (
        <Loader />
    ) : error && error?.message !== "Cannot lock draft for editing" ? (
        <ErrorPage error={error?.message} />
    ) : (
        <Lock
            id={code}
            breadcrumb={"Schedule Locked"}
            message={"This schedule is currently locked because the person indicated below is making changes to it."}
        >
            <ScheduleEditContents id={id} code={code} {...config} definition={definition} />
        </Lock>
    )
}

export function ScheduleEditContents({ id, code, definition, preview }) {
    const isUnlocked = useRef(false)
    UnlockDraft.useEvent(() => (isUnlocked.current = true), [isUnlocked])
    const removeListener = useRef(noop)
    const activeSchedule = useRef()
    const editRefresh = useRefresh()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const save = useCallback(
        debounce(() => updateDraftOnServer(false), 5000, { maxWait: 30000 }),
        []
    )

    useEffect(() => {
        const timerId = setInterval(stillEditing(id), 15000)
        return async () => {
            clearInterval(timerId)
            EditEnding.raise()
            await delay(50)
            await updateDraftOnServer(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const [schedule, { onChange, ...props }] = useUndo({ initialTarget: definition })
    activeSchedule.current = schedule

    initialize(schedule)
    if (schedule) {
        schedule.id = uniqueId(schedule._id)
    }
    return (
        <Bound
            editMode={!preview}
            {...props}
            editRefresh={editRefresh}
            onChange={notifyChanged}
            target={schedule}
            schedule={schedule}
            immediateSave={() => updateDraftOnServer(false)}
        >
            <SaveIndicator id="schedule" />
            {preview ? !!schedule && <ScheduleDisplay /> : !!schedule && states(schedule)}
        </Bound>
    )

    async function updateDraftOnServer(unlock = true) {
        if (isUnlocked.current) {
            return
        }
        isUnlocked.current = unlock
        removeListener.current()
        removeListener.current = noop
        const toSave = { ...activeSchedule.current }
        toSave.legislation ??= []
        toSave.legislation = toSave.legislation.unique()
        try {
            await mutate(
                gql`
                    mutation updateDraft($id: String!, $content: JSONObject!, $unlock: Boolean) {
                        writeContent(id: $id, content: $content, unlock: $unlock)
                    }
                `,
                {
                    unlock,
                    content: toSave,
                    id: code,
                },
                { fetchPolicy: "network-only" }
            )
            ToBeSaved("schedule").raise(false)
        } catch (error) {
            ToBeSaved("schedule").raise(error)
            console.error(error, toSave, code)
            if (error.message !== "Cannot lock draft for editing") {
                await beep()
                errorSnackbar("Could not save document! Your changes were not saved.", { persist: true })
            }
        }
    }

    function notifyChanged(...params) {
        removeListener.current()
        window.addEventListener("beforeunload", saveBeforeExit, { capture: true })
        removeListener.current = () => window.removeEventListener("beforeunload", saveBeforeExit, { capture: true })
        save()
        onChange(...params)
        ToBeSaved("schedule").raise(true)

        function saveBeforeExit(event) {
            event.preventDefault()
            updateDraftOnServer(true).catch(console.error)
            event.returnValue = "Your changes are being saved, continue?"
        }
    }
}
