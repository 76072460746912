import { Button } from "@mui/material"
import { confirm } from "lib/@dialogs/confirm"
import { removeAllUsers } from "plugins/database-pack/controller/upload-pack"
import { infoSnackbar } from "lib/snackbar/info-snackbar"

export function ClearUsers() {
    return (
        <Button onClick={remove} sx={{ alignSelf: "center" }} variant="contained" color="secondary">
            Remove All Users
        </Button>
    )

    async function remove() {
        if (await confirm("Are you sure? Your own user will not be removed.", "Remove ALL Users")) {
            await removeAllUsers()
            infoSnackbar("All users removed")
        }
    }
}
