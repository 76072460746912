import { api } from "lib/graphql/api"
import { gql } from "@apollo/client"
import { mutate } from "lib/graphql/mutate"
import { makeBusyFunction } from "lib/@components/busy"

export const uploadPack = makeBusyFunction(
    api((pack, name) =>
        mutate(
            gql`
                mutation UploadPack($pack: String!, $name: String) {
                    uploadPack(pack: $pack, createClient: $name)
                }
            `,
            { pack, name },
            { returns: "uploadPack" }
        )
    ),
    "Uploading Pack"
)

export const removeAllUsers = api(() =>
    mutate(
        gql`
            mutation RemoveAllUsers {
                removeAllUsers
            }
        `,
        {},
        { returns: "removeAllUsers" }
    )
)
