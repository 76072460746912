import { gql } from "@apollo/client"
import { api } from "lib/graphql/api"
import { query } from "lib/graphql/query"

export const getPagedUsersForRegimeAccess = api(async ({ regimeId, searchTerm, skip, take, orderStmt, filterStmt }) =>
    query(
        gql`
            query GetPagedUsersForRegimeAccess(
                $regimeId: String!
                $searchTerm: String
                $skip: Int
                $take: Int
                $orderStmt: [String]
                $filterStmt: String
            ) {
                getPagedClientUsersForRegimeAccess(
                    regimeId: $regimeId
                    searchTerm: $searchTerm
                    skip: $skip
                    take: $take
                    orderStmt: $orderStmt
                    filterStmt: $filterStmt
                ) {
                    totalCount
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                    }
                    edges {
                        node {
                            userId
                            displayName
                            photoURL
                            demands
                            jobTitle
                            hasAccess
                            isCurrentUser
                        }
                        cursor
                    }
                }
            }
        `,
        { regimeId, searchTerm, skip, take, orderStmt, filterStmt },
        { returns: "getPagedClientUsersForRegimeAccess", fetchPolicy: "network-only" }
    )
)
