import { Box } from "@mui/material"
import Iconify from "minimals-template/components/Iconify"
import { relevantDemands, relevantIcons } from "../regime-access-config"

export function RolesIcons({ demands }) {
    const enabled = "primary.main"
    const disabled = "lightgrey"
    return (
        <Box display="flex" alignItems="center" pl={1}>
            <Iconify
                icon={"ic:round-remove-red-eye"}
                sx={{
                    mr: 1,
                    color: enabled,
                    fontSize: 25,
                }}
            />
            {relevantDemands.map((demand, idx) => (
                <Iconify
                    key={idx}
                    icon={relevantIcons[demand]}
                    sx={{
                        mr: 1,
                        color: demands[demand] ? enabled : disabled,
                        fontSize: 25,
                    }}
                />
            ))}
        </Box>
    )
}
