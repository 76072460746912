import { getRecyclerContents } from "routes/schedule/plugins/recycle-bin/controller/recycle-bin-controller"
import { useSearchParam } from "lib/@hooks/use-search-param"
import { useMemo, useState } from "react"
import { Grid, List, Typography } from "@mui/material"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { BinItem } from "routes/schedule/plugins/recycle-bin/components/bin-item"
import { NoResults } from "lib/@components/no-results/no-results"
import { RecycleType } from "event-definitions"
import { Repeat } from "lib/@components/repeat"
import { SearchBoxUsingSearchParam } from "lib/@components/searchBoxUsingSearchParam"
import { Summary } from "routes/schedule/plugins/recycle-bin/components/summary"
import { DeletedSummary } from "routes/schedule/plugins/recycle-bin/components/deleted-summary"
import { EXPIRING_SOON, REMAINDER, WITHIN_A_WEEK } from "routes/schedule/plugins/recycle-bin/components/constants"

const EXPIRING_SOON_DAYS = 2
const WITHIN_A_WEEK_DAYS = 7

export function BinItems() {
    const [filter, setFilter] = useState("")
    const [ageFilter, setAgeFilter] = useState("")
    let items = getRecyclerContents.useResults() ?? []
    items.forEach((item) => {
        item.viewType = RecycleType.call({ item, type: null })
        item.date = new Date(item.date)
    })
    const windowStart = new Date().beginningOfDay().addDays(-30)
    items = items.filter((i) => i.date > windowStart)
    const itemGroups = items.groupBy((i) => {
        const days = Math.floor(i.date.daysSince(windowStart))
        i.days = days
        console.log({ days }, i.name)
        if (days <= EXPIRING_SOON_DAYS) {
            return EXPIRING_SOON
        }
        if (days <= WITHIN_A_WEEK_DAYS) {
            return WITHIN_A_WEEK
        }

        return REMAINDER
    })

    const groups = items.groupBy("viewType")
    delete groups.null

    const [search] = useSearchParam("search")
    const list = useMemo(
        () =>
            (items ?? [])
                .filter((i) => {
                    switch (ageFilter) {
                        case EXPIRING_SOON:
                            return i.days <= EXPIRING_SOON_DAYS
                        case WITHIN_A_WEEK:
                            return i.days <= WITHIN_A_WEEK_DAYS

                        case REMAINDER:
                            return i.days > WITHIN_A_WEEK_DAYS
                        default:
                            return true
                    }
                })
                .filter((i) => !search?.trim() || i.name?.toLowerCase().includes(search.trim())),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [search, JSON.stringify(items), ageFilter]
    )
    return (
        <>
            <Grid container my={2} spacing={1} sx={{ m: "auto", maxWidth: 800 }}>
                {!!Object.keys(itemGroups).length && (
                    <Grid item xs={12}>
                        <Typography color="text.secondary" variant="overline">
                            Recycle Bin Expiration
                        </Typography>
                    </Grid>
                )}
                <Repeat
                    list={Object.entries(itemGroups)}
                    item={<DeletedSummary setFilter={updateAgeFilter} current={ageFilter} total={items.length} />}
                />
            </Grid>
            <Grid container my={1} spacing={1} sx={{ m: "auto", maxWidth: 800 }}>
                {!!Object.keys(groups).length && (
                    <Grid item xs={12}>
                        <Typography variant="overline" color="text.secondary">
                            Item Types
                        </Typography>
                    </Grid>
                )}
                <Repeat
                    list={Object.entries(groups)}
                    item={<Summary total={items.length} setFilter={updateFilter} current={filter} />}
                />
            </Grid>
            <SearchBoxUsingSearchParam placeholder="Search by name..." />
            <List>
                {!!list.length && (
                    <PagedRepeat
                        pageSize={8}
                        list={list.filter(({ viewType }) => !filter || viewType === filter)}
                        bind
                        item={<BinItem />}
                        showTop
                    />
                )}
                {!list.length && <NoResults />}
            </List>
        </>
    )

    function updateAgeFilter(filter) {
        setFilter("")
        setAgeFilter((current) => {
            if (current === filter) {
                return ""
            }
            return filter
        })
    }

    function updateFilter(filter) {
        setFilter((current) => {
            if (current === filter) {
                return ""
            }
            return filter
        })
    }
}
