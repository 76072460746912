import { ClientTreeUpdated, CurrentClientChanged, RefreshTreeRoots, SidebarTreeRoots } from "event-definitions"
import { cacheTreeRoot, linkUncache, removeCache } from "lib/cached"
import { MyTree } from "routes/schedule/my-schedules/my-custom-schedules/my-tree"
import { MySchedules } from "routes/schedule/my-schedules/my-custom-schedules/my-schedules"
import { getClientInfo } from "minimals-template/components/contexts/NognitoContext"
import { MyList } from "routes/schedule/my-schedules/my-custom-schedules/my-list"
import { BasicFolder } from "routes/schedule/components/basic-folder"
import { isActiveWhen } from "routes/schedule/components/is-active-when"
import { TreeRoot } from "library/tree"
import { addTempBusy } from "lib/@components/busy"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { Box } from "@mui/material"
import { EDIT_TREE, isGroup, LIVE_TREE, READY_TREE } from "library/constants"
import {
    MY_DRAFTS,
    MY_LIST,
    MY_PUBLISHED,
    MY_RETIRED,
} from "routes/schedule/my-schedules/my-custom-schedules/lib/constants"
import { getClientGroups } from "routes/schedule/my-schedules/my-custom-schedules/controller/get-client-trees"
import { createDynamicTreeRetriever } from "routes/schedule/my-schedules/my-custom-schedules/lib/create-dynamic-tree-retriever"
import { LiveTree } from "routes/schedule/my-schedules/my-custom-schedules/live-tree"
import { uniqueId } from "library/database/split-id"
import { captureNow } from "FrozenRouter"
import { setRootOfMaintenance } from "routes/regime/lib/root-of-maintenance"
import { hasDemand } from "lib/authorization/has-demand"
import { registerConditionalPageNavigation } from "lib/routes/register-conditional-page-navigation"
import { addDebugItem } from "debug-window"
import { getClientProfile } from "routes/client-admin/plugins/profile/controller/get-client-profile"
import { ReadyTree } from "routes/schedule/my-schedules/my-custom-schedules/ready-tree"
import { getTreeIndex } from "routes/schedule/lib/getTreeIndex"

CurrentClientChanged.handleOnce(() => {
    removeCache("mySchedules")
    RefreshTreeRoots.raiseOnce()
})

export function MyCompanyNavHeader() {
    const profile = getClientProfile.useResults()
    return (
        <Box sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {profile?.name ?? "Your Company"}
        </Box>
    )
}

const libraryRegimes = ["legislation", "uniclass", "nrm", "selected", "sfg20"]

registerConditionalPageNavigation(
    () => hasDemand("multipleRegimes") && hasDemand("!sharing"),
    `/app/schedules?id=${EDIT_TREE}`,
    "Maintenance Regimes",
    "pajamas:issue-type-maintenance",
    {
        group: "Planning",
        isActive: isActiveWhen(
            (c) => c.id === EDIT_TREE,
            ({ pathname }) =>
                pathname.includes("facilities") ||
                (pathname.includes("~") &&
                    pathname.includes("!") &&
                    !libraryRegimes.includes(pathname.split("!")[0].split("~").at(-1))) ||
                (pathname.includes("/plan/") && !(pathname.includes(LIVE_TREE) || pathname.includes("~live")))
        ),
        priority: 150,
    }
)

registerConditionalPageNavigation(
    () => hasDemand("scheduleEditor") && hasDemand("!sharing") && hasDemand("schedules"),
    `/app/schedules?id=${MY_LIST}`,
    "Custom Schedules",
    "ion:documents-outline",
    {
        group: "Schedules",
        // groupContent: <MyCompanyNavHeader />,
        onClick: captureNow(addTempBusy),
        isActive: isActiveWhen((c) => c.id === MY_LIST),
        priority: 160,
    }
)

SidebarTreeRoots.handleOnce(
    cacheTreeRoot("mySchedules", async ({ add }) => {
        addDebugItem("My Schedules is refreshed")
        const trees = await getClientGroups()
        const tree = {
            content: <MyTree />,
            priority: 25,
            id: EDIT_TREE,
            label: "Maintenance Regimes",
            [TreeRoot]: true,
            [PlanRoot]: false,
            folderHandling: false,
            visible: true,
            hasChildren: true,
        }
        setRootOfMaintenance(tree)
        tree.children = trees.sortBy("name").map((t) => createDynamicTreeRetriever({ definition: t, tree }))
        const live = {
            content: <LiveTree />,
            priority: 35,
            id: LIVE_TREE,
            label: "Live Regime",
            [PlanRoot]: true,
            [TreeRoot]: true,
            folderHandling: false,
            visible: true,
            hasChildren: true,
        }
        live.children = trees
            .filter((c) => c.hasLive)
            .map((t) =>
                createDynamicTreeRetriever({
                    definition: t,
                    tree,
                    type: "live",
                    map: (v) =>
                        isGroup(v)
                            ? { ...v, id: !v.id.includes("|") ? `~live|${uniqueId(t._id)}|${v.id}` : `${v.id}` }
                            : v,
                    folderHandling: false,
                })
            )
        const ready = {
            content: <ReadyTree />,
            priority: 36,
            id: READY_TREE,
            label: "Published Regime",
            [PlanRoot]: true,
            [TreeRoot]: true,
            folderHandling: false,
            visible: true,
            hasChildren: true,
        }
        ready.children = trees
            .filter((c) => c.hasReady)
            .map((t) =>
                createDynamicTreeRetriever({
                    definition: t,
                    tree,
                    type: "ready",
                    map: (v) => (isGroup(v) ? { ...v, id: `~ready|${uniqueId(t._id)}|${v.id}` } : v),
                    folderHandling: false,
                })
            )

        add({
            content: <MySchedules />,
            id: "mySchedules",
            [TreeRoot]: true,
            priority: 125,
            label: getClientInfo()?.name ?? "Your Company",
            visible: (c) => !c.inSelector,
            children: [
                tree,
                live,
                ready,
                {
                    content: <MyList />,
                    priority: 50,
                    id: MY_LIST,
                    [TreeRoot]: true,
                    label: "Custom Schedules",
                    visible: true,
                    hasChildren: true,
                    children: [
                        Object.defineProperties(
                            {
                                id: MY_DRAFTS,
                                label: "Drafts",
                                visible: true,
                                hasChildren: true,
                                content: <BasicFolder color="myStuff.main" caption="Drafts" />,
                            },
                            {
                                children: {
                                    get() {
                                        return getTreeIndex().retrieve("allDrafts")?.children
                                    },
                                },
                            }
                        ),
                        Object.defineProperties(
                            {
                                id: MY_PUBLISHED,
                                label: "Published",
                                visible: true,
                                hasChildren: true,
                                content: <BasicFolder color="myStuff.main" caption="Published" />,
                            },
                            {
                                children: {
                                    get() {
                                        return getTreeIndex().retrieve("allPublished")?.children
                                    },
                                },
                            }
                        ),
                        Object.defineProperties(
                            {
                                id: MY_RETIRED,
                                label: "Retired",
                                visible: true,
                                hasChildren: true,
                                content: <BasicFolder color="myStuff.main" caption="Retired" />,
                            },
                            {
                                children: {
                                    get() {
                                        return getTreeIndex().retrieve("allRetired")?.children
                                    },
                                },
                            }
                        ),
                    ],
                },
            ],
        })
        ClientTreeUpdated.raiseOnce(tree)
    })
)

//linkUncache("schedule", "mySchedules")
linkUncache("schedule", "drafts")
