import { uniqueId } from "library/database/split-id"
import { generate } from "library/guid"
import { isIdInGroup } from "library/id-helpers"
import { parentTreeItem } from "library/tree"
import { getScheduleRecordId } from "routes/schedule/lib/get-schedule-record-id"
import { registerSymbol } from "library/serialize-circular"

export const idIndex = registerSymbol(Symbol("idIndex"))
export const DontIndexChildren = registerSymbol(Symbol("dontIndexChildren"))

export function getTreeItemIndex(root) {
    if (!root) return {}
    const index = { [root.id]: root, [idIndex]: {}, retrieve, addToIndex }
    recurseAdd(root, index)
    return index

    function addToIndex(parent, itemOrList) {
        if (Array.isArray(itemOrList)) {
            addChildren(parent, itemOrList, index)
        } else {
            recurseAdd(parent, index)
        }
    }

    function retrieve(item, hint) {
        if (typeof item === "string" && isIdInGroup(item)) {
            return retrieve(item.split("!").at(-1), item)
        }
        hint ??= generate()
        return index[hint] ?? index[idIndex][item]?.[0] ?? index[item] ?? index[idIndex][`${item}:sfg20/schedule`]?.[0]
    }
}

function addChildren(parent, children, index, seen = new Set()) {
    for (const item of (children ?? []).filter(Boolean)) {
        if (!(parentTreeItem in item)) {
            item[parentTreeItem] = parent
            if (item._id) {
                item.id = `${parent.id}!${uniqueId(item._id)}`
            }
        }
        index[item.id] = item

        if (!item.isAlias) {
            if (item._id) {
                const list = (index[idIndex][item._id] = index[idIndex][item._id] || [])
                list.push(item)
            }
            index[getScheduleRecordId(item.id)] = item // we may need to remove getUniqueId
        }
        if (!item[DontIndexChildren]) {
            if (item.children) {
                recurseAdd(item, index, seen)
            }
        }
    }
}

function recurseAdd(root, index, seen = new Set()) {
    if (seen.has(root)) return
    seen.add(root)
    addChildren(root, root.children, index, seen)
}
