import { registerTab } from "lib/@components/tabs"
import { hasDemand } from "lib/authorization/has-demand"
import { Lazy } from "lib/make-lazy"
import { MainStyleStart } from "slot-definitions"
import { SessionAlertBanner } from "./plugins/session-alert-banner"
import { registerFeature } from "lib/features"
import { isEnabled } from "lib/@components/feature"

registerTab({
    tab: "clientAdmin",
    id: "adoptClientRestriction",
    title: "SFG20 Remote Access",
    content: (
        <Lazy importer={() => import("./adopt-client-restriction")} extract="AdoptClientRestriction" priority={175} />
    ),
    predicate: () => hasDemand("clientAdmin") && isEnabled(FEATURE_FLAG_ADOPT_CLENT),
})

export const FEATURE_FLAG_ADOPT_CLENT = "adoptClient"
registerFeature("Adopt Client", FEATURE_FLAG_ADOPT_CLENT)

MainStyleStart.plug(<SessionAlertBanner />)
