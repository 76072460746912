import { Box, ListItemButton, Typography } from "@mui/material"
import { PropertyBox } from "lib/@components/property-box"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FolderItemPart } from "routes/schedule/tree/schedules/folder-item-part"
import { Selected } from "routes/schedule/components/selected"
import Iconify from "minimals-template/components/Iconify"
import {
    PublishedItemBelow,
    PublishedItemMain,
    PublishedItemRight,
    ScheduleItemBeneath,
    ScheduleItemCode,
} from "slot-definitions"
import { DateDisplay } from "lib/@components/dateDisplay"
import { OpenSchedule } from "event-definitions"
import { uniqueId } from "library/database/split-id"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { ContextMenuBar } from "lib/@components/context-menu-bar"
import { BlockClicks } from "lib/@components/block-clicks"
import { TruncatedTypography } from "lib/@components/truncated-typography"

export function PublishedItem() {
    const { target } = useBoundContext()
    return (
        <ListItemButton onClick={edit} className="published-item">
            <PropertyBox spacing={0}>
                <ListItemBox spacing={1}>
                    <FolderItemPart>
                        <Selected />
                    </FolderItemPart>
                    <FolderItemPart>
                        <Iconify
                            icon="mdi:file-document-outline"
                            sx={{ width: 32, height: 32, color: "primary.dark" }}
                        />
                    </FolderItemPart>
                    <PublishedItemMain.Slot schedule={target}>
                        <Box display="flex" alignItems="center" flex={1}>
                            <TruncatedTypography>{target.title || target._id}</TruncatedTypography>
                            <Typography
                                component="div"
                                variant="caption"
                                sx={{ color: "text.secondary", ml: 2.5, mb: 0.1 }}
                            >
                                v{target.version}
                            </Typography>
                            <Typography
                                variant="caption"
                                component="div"
                                sx={{ ml: 0.5, mb: 0.1, color: "text.secondary", opacity: 0.5 }}
                            >
                                <DateDisplay
                                    modify={(t) =>
                                        `(${t.replace(/\s+minute(s*)/gi, "m").replace(/\s+second(s*)/gi, "s")})`
                                    }
                                    maxTime={1000 * 60 * 60}
                                    date={target.modified}
                                />
                            </Typography>
                        </Box>
                    </PublishedItemMain.Slot>
                    <PublishedItemRight.Slot schedule={target}>
                        <Box textAlign="right" display="flex" alignItems="center">
                            <ScheduleItemCode.Slot schedule={target}>
                                <Typography component="div" variant="caption">
                                    #{target.code}
                                </Typography>
                            </ScheduleItemCode.Slot>
                            <Box width={54} />
                            <Box width={90} sx={{ textAlign: "left" }}>
                                <Typography variant="caption">
                                    <DateDisplay date={target.modified} />
                                </Typography>
                            </Box>
                        </Box>
                        <ContextMenuBar type="published" />
                    </PublishedItemRight.Slot>
                </ListItemBox>
                <PublishedItemBelow.Slot schedule={target} />
                <BlockClicks sx={{ flexDirection: "column" }}>
                    <ScheduleItemBeneath.Slot schedule={target} />
                </BlockClicks>
            </PropertyBox>
        </ListItemButton>
    )

    function edit() {
        OpenSchedule(target._id).raiseAsync({ id: `allPublished!${uniqueId(target._id)}` })
    }
}
