import { Box, FormControlLabel, MenuItem, Pagination, Select, Stack, Typography } from "@mui/material"
import { setFromValueParam } from "lib/setFromEvent"
import { GenericPaginationBar } from "slot-definitions"

export function GenericPagination({
    defaultRecords = [5, 10, 25, 50],
    disableRowsPerPage = false,
    numberOfPages,
    currentPage,
    changeRowsPerPage,
    take,
    changePage,
    pageInfo,
    showPageInfo = false,
}) {
    return (
        <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent={showPageInfo ? "space-between" : "space-evenly"}
        >
            <GenericPaginationBar.Slot />
            {showPageInfo && pageInfo}
            <Box flex={1} />
            <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between">
                {!disableRowsPerPage && (
                    <FormControlLabel
                        label={<Typography>Rows per page: </Typography>}
                        labelPlacement="start"
                        sx={{ marginBottom: 2 }}
                        control={
                            <Select
                                sx={{ mr: 2, ml: 2 }}
                                variant="standard"
                                labelId="rows-per-page-label"
                                value={take}
                                onChange={changeRowsPerPage}
                            >
                                {defaultRecords.map((rowCountValue) => (
                                    <MenuItem key={rowCountValue} value={rowCountValue}>
                                        {rowCountValue}
                                    </MenuItem>
                                ))}
                            </Select>
                        }
                    />
                )}

                <Pagination
                    color="primary"
                    onChange={setFromValueParam(changePage)}
                    shape="circular"
                    count={numberOfPages}
                    page={currentPage}
                    sx={{ marginBottom: 2 }}
                />
            </Stack>
        </Stack>
    )
}

export default GenericPagination
