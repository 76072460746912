import { ClientPanels, ClientTabs } from "slot-definitions"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { PagedRepeat } from "lib/@components/paged-repeat"
import { useRefresh } from "lib/@hooks/useRefresh"
import { Bound } from "lib/@components/binding/Bound"
import { BoundTextField, BoundTypography } from "lib/@components/binding/bound-components"
import React, { useState } from "react"
import { Grid, List, Typography } from "@mui/material"
import { BoundCheckBox } from "lib/@components/standard-checkbox"
import { getAvailableProductsArray } from "library/hubspot/get-products"
import { getFubspot } from "routes/settings/plugins/fubspot/controller/get-fubspot"
import { useSave } from "lib/@hooks/use-save"
import { setFubspot } from "routes/settings/plugins/fubspot/controller/set-fubspot"
import { fubspotEnabled } from "routes/settings/plugins/fubspot/controller/fubspot-enabled"
import { SecureTab, SecureTabPanel } from "minimals-template/components/secure-tabs"
import { Frag } from "lib/@components/slot/frag"

ClientTabs.plug(<SecureTab demand={"admin-fubspot"} hide={true} fallback={<Frag />} label="FubSpot" value="FubSpot" />)

export function FubspotPanel() {
    const isFubspotEnabled = fubspotEnabled.useResults() ?? false
    console.log({ isFubspotEnabled })
    if (!isFubspotEnabled) {
        return <>FubSpot not enabled on this environment</>
    }
    return <FubSpot />
}

ClientPanels.plug(
    <SecureTabPanel demand={"admin-fubspot"} hide={true} fallback={<Frag />} value="FubSpot">
        <FubspotPanel priority={150} />
    </SecureTabPanel>
)

export function FubSpot() {
    const { clientId: client } = useBoundContext()
    const fubspotProducts = getAvailableProductsArray()
    const [newState] = useState({})
    const clientFubspotData = getFubspot.useResults(client) ?? newState
    // get fubspot data for client

    const onChange = useSave({ save, target: clientFubspotData, id: client })
    const refresh = useRefresh()

    return (
        !!clientFubspotData && (
            <Bound onChange={onChange} refresh={refresh} target={clientFubspotData}>
                <List className="fubspot-products">
                    <PagedRepeat showTop pageSize={40} list={fubspotProducts} item={<FubspotProduct />} />
                </List>
            </Bound>
        )
    )

    async function save(data) {
        await setFubspot(client, data)
    }
}

function FubspotProduct({ item }) {
    const { target } = useBoundContext()
    target.settings ??= {}
    const settings = (target.settings[item.sku] ??= { ...item })
    const refresh = useRefresh()

    return (
        <Bound refresh={refresh} target={settings}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={2}>
                    <BoundTypography field="sku" variant="body2" />
                </Grid>
                <Grid item xs={2}>
                    {!item.hasQuantity && <Typography>N/A</Typography>}
                    {item.hasQuantity && <BoundTextField field="quantity" label="Quantity" yup="number().required" />}
                </Grid>
                <Grid item xs={2}>
                    <BoundCheckBox label="Enabled" field="status" indterminate={item.productStatus === "active"} />
                </Grid>
                <Grid item xs={4}>
                    {item.regimes !== 0 && <>{item.regimes} Regimes, </>}
                    {item.createLicences !== 0 && <>{item.createLicences} Create, </>}
                    {item.collaborateLicences !== 0 && <>{item.collaborateLicences} Collaborate, </>}
                    {item.viewLicences !== 0 && <>{item.viewLicences} View, </>}
                    {item.demands.length > 0 && <>Grants demands: {JSON.stringify(item.demands)}</>}
                </Grid>
            </Grid>
        </Bound>
    )
}
