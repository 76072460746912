import { Bound } from "lib/@components/binding/Bound"
import { isEqual } from "lib/isEqual"
import { Box, IconButton, Tooltip } from "@mui/material"
import { MdCircle } from "@react-icons/all-files/md/MdCircle"
import { noChange, useRefresh } from "lib/@hooks/useRefresh"
import { MdUndo } from "@react-icons/all-files/md/MdUndo"
import { RenderSelector } from "event-definitions"
import { DarkThemeProvider } from "theme/darkThemeProvider"
import { useBoundContext } from "lib/@components/binding/use-bound-context"

export function Different({ field, comparedTo, children }) {
    const { target } = useBoundContext()
    RenderSelector.useRefresh(noChange)
    const refresh = useRefresh()
    const isDifferent = Object.get(target, field) && !isEqual(Object.get(target, field), Object.get(comparedTo, field))
    const title = (
        <Bound editMode={false} target={comparedTo}>
            {children}
        </Bound>
    )
    return isDifferent ? (
        <Tooltip
            arrow
            placement="top-start"
            title={
                isDifferent && (
                    <DarkThemeProvider>
                        <Bound mustShowContent={true}>
                            <Box display="flex" alignItems="center">
                                <Box mr={1}>{title}</Box>
                                <IconButton size="small" color="secondary" onClick={revert}>
                                    <MdUndo />
                                </IconButton>
                            </Box>
                        </Bound>
                    </DarkThemeProvider>
                )
            }
        >
            <Box display="flex" alignItems="center">
                <Box color="warning.main" sx={{ zoom: 0.5 }} fontSize={16} width={16} pl={1}>
                    <MdCircle />
                </Box>

                {children}
            </Box>
        </Tooltip>
    ) : (
        <>
            <Box width={8} />
            {children}
        </>
    )

    function revert() {
        Object.set(target, field, Object.get(comparedTo, field) ?? "")
        refresh()
    }
}
