import React from "react"
import Box from "@mui/material/Box"
import useIsXsScreen from "minimals-template/components/@hooks/useIsXsScreen"

export function TriFlex(props) {
    const { children, ...rest } = props

    const leftChild = React.Children.toArray(children).find((child) => child.type === TriFlex.Left)
    let centerChild = React.Children.toArray(children).find((child) => child.type === TriFlex.Center)
    const rightChild = React.Children.toArray(children).find((child) => child.type === TriFlex.Right)

    if (!centerChild && !leftChild && !rightChild) {
        centerChild = children
    }

    const isXsScreen = useIsXsScreen()

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: isXsScreen ? "column" : "row",
                width: "100%",
                alignItems: isXsScreen ? "flex-start" : "center",
            }}
            {...rest}
        >
            {leftChild ? <Box sx={{ flex: "1 0 0", minWidth: "max-content" }}>{leftChild}</Box> : null}
            {centerChild ? (
                <Box sx={{ flex: "1 1 auto", minWidth: 0, width: "100%", my: isXsScreen ? 2 : undefined }}>
                    {centerChild}
                </Box>
            ) : null}
            {rightChild ? <Box sx={{ flex: "1 0 0", minWidth: "max-content", marginLeft: 1 }}>{rightChild}</Box> : null}
        </Box>
    )
}

function Left(props) {
    return props.children
}

function Center(props) {
    return <Box sx={{ flex: "0 1 auto" }}>{props.children}</Box>
}

function Right(props) {
    return props.children
}

TriFlex.Left = Left
TriFlex.Center = Center
TriFlex.Right = Right

export default TriFlex
