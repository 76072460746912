import { Bound } from "lib/@components/binding/Bound"
import { Box, Container, Tab } from "@mui/material"
import { FacilityCompleteCard } from "routes/schedule/my-schedules/my-group-types/facility/facilityCompleteCard"
import { OneCard } from "lib/@components/one-card"
import { StatsPanels, StatsTabs } from "slot-definitions"
import { setFromValueParam, setOnlyTruthy } from "lib/setFromEvent"
import { EnforceTabList } from "lib/@components/enforceTabList"
import { useEffect } from "react"
import { TabContext, TabPanel } from "@mui/lab"
import { ListItemBox } from "lib/@components/ListItemBox"
import { FoldersAndSchedules } from "routes/regime/components/folders-and-schedules"
import { navigate } from "lib/routes/navigate"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { useReferenceState } from "lib/@hooks/use-reference"

export function Details() {
    const { facility, parentItem } = useBoundContext()

    const [tab, setTabReal] = useReferenceState("detailsTab", "")
    const tabs = StatsTabs.useSlot()
    useEffect(() => {
        if (!tab && tabs.length) {
            setTabReal(tabs[0]?.props?.value)
        }
    }, [tabs, tab, setTabReal])
    StatsTabs.usePlug(<Tab value="Folders" label="Spaces, Folders & Schedules" />)
    StatsPanels.usePlug(
        <TabPanel value="Folders">
            <FoldersAndSchedules />
        </TabPanel>
    )
    return (
        <Bound select={select}>
            <TabContext value={tab}>
                <Box mt={1}>
                    <Container maxWidth="lg">
                        <OneCard>
                            <FacilityCompleteCard facility={facility.data} hideMenu={true} />
                            <ListItemBox pr={2}>
                                <Box flex={1} />
                                <EnforceTabList onChange={setFromValueParam(setOnlyTruthy(setTabReal))}>
                                    {tabs}
                                </EnforceTabList>
                            </ListItemBox>
                        </OneCard>
                    </Container>
                    <Box pb={2} />
                    <StatsPanels.Slot />
                </Box>
                <Box mb={60} />
            </TabContext>
        </Bound>
    )

    function select(item) {
        return () => {
            navigate(`/app/facilities/facility/${parentItem?.id}/${item.id}`)
        }
    }
}
