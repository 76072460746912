/* eslint-disable react-hooks/exhaustive-deps */
import { useDebouncedValue } from "lib/@hooks/useDebouncedValue"
import { useCallback, useEffect, useRef, useState } from "react"
import { useRefresh } from "./useRefresh"

export function usePaginatedQuery({
    defaultSkip = 0,
    defaultTake = 10,
    defaultSearchTerm = "",
    defaultDebounceTime = 350,
    query = queryNoop,
    queryParams = {},
}) {
    const urlParams = new URLSearchParams(window.location.search)
    const initialSkip = parseInt(urlParams.get("skip"), 10) || defaultSkip
    const initialTake = parseInt(urlParams.get("take"), 10) || defaultTake
    const initialSearchTerm = decodeURIComponent(urlParams.get("search") || "") || defaultSearchTerm

    const [take, setTake] = useState(initialTake)
    const [skip, setSkip] = useState(initialSkip)
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm)
    const [search, setSearch] = useDebouncedValue(searchTerm, defaultDebounceTime, setSearchTerm)
    const [localQueryParams, setLocalQueryParams] = useState(queryParams)
    useEffect(() => {
        if (JSON.stringify(queryParams) !== JSON.stringify(localQueryParams)) setLocalQueryParams(queryParams)
    }, [queryParams, localQueryParams, setLocalQueryParams])

    const {
        loading,
        error,
        data: results,
    } = query.useResults.status({
        searchTerm,
        skip,
        take,
        ...localQueryParams,
    })

    const handleRefresh = useRefresh()
    const isFirstRender = useRef(true)

    const calculateCurrentPage = useCallback(() => Math.floor(skip / take) + 1, [skip, take])
    const calculateNumberOfPages = useCallback(() => Math.ceil(results?.totalCount / take) || 0, [results])
    const changePage = useCallback((page) => setSkip((page - 1) * take), [take])
    const nextPage = useCallback(() => setSkip((prevSkip) => prevSkip + take), [take])
    const prevPage = useCallback(() => setSkip((prevSkip) => Math.max(prevSkip - take, 0)), [take])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
        } else {
            handleURLUpdate()
        }
    }, [searchTerm, skip, take])

    useEffect(handleRefresh, [skip, take, searchTerm])

    useEffect(() => {
        const handlePopState = (event) => {
            console.log({ event })
            if (event.state) {
                setSkip(event.state?.skip || initialSkip)
                setTake(event.state.take || initialTake)
                setSearch(event.state.search || defaultSearchTerm)
                setSearchTerm(event.state.search || defaultSearchTerm)
            } else {
                const params = new URLSearchParams(window.location.search)
                setSkip(parseInt(params.get("skip"), 10) || initialSkip)
                setTake(parseInt(params.get("take"), 10) || initialTake)
                setSearch(params.get("search") || defaultSearchTerm)
            }
        }
        window.addEventListener("popstate", handlePopState)
        return () => {
            window.removeEventListener("popstate", handlePopState)
            const currentURL = new URL(window.location.href)
            currentURL.searchParams.delete("skip")
            currentURL.searchParams.delete("take")
            currentURL.searchParams.delete("search")
            console.log({ currentURL })
            window.history.replaceState({}, "", currentURL.toString())
        }
    }, [])

    const changeRowsPerPage = useCallback((event) => {
        setTake(parseInt(event.target.value, 10))
        setSkip(0)
    }, [])

    const onSearchChange = useCallback((value) => {
        setSearch(value)
        setSkip(0)
    }, [])

    function handleURLUpdate() {
        const currentURL = new URL(window.location.href)
        const encodedSearchTerm = encodeURIComponent(searchTerm)
        currentURL.searchParams.set("skip", skip?.toString() ?? initialSkip.toString())
        currentURL.searchParams.set("take", take.toString() ?? initialTake.toString())
        currentURL.searchParams.set("search", encodedSearchTerm ? encodedSearchTerm : "")

        const newUrlString = currentURL.toString()
        if (window.location.href !== newUrlString) {
            window.history.pushState({ skip, take, search: searchTerm }, "", newUrlString)
        }
    }

    return {
        changePage,
        changeRowsPerPage,
        currentPage: calculateCurrentPage(skip, take),
        error,
        loading,
        localQueryParams,
        nextPage,
        numberOfPages: calculateNumberOfPages(results?.totalCount, take),
        onSearchChange,
        pageInfo: results?.pageInfo,
        prevPage,
        refresh: handleRefresh,
        results,
        search,
        setLocalQueryParams,
        setSearch: onSearchChange,
        setSkip,
        skip,
        take,
        totalCount: results?.totalCount,
    }
}

function queryNoop() {
    return { useResults: { status: () => ({ loading: false, error: null, data: {} }) } }
}
