import { Button, ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material"
import { useDialog } from "lib/@hooks/useDialog"
import Iconify from "minimals-template/components/Iconify"
import { createSubFolder } from "routes/schedule/folder-manager/controller/create-sub-folder"
import { FolderContextMenu } from "routes/schedule/folder-manager/lib/folder-context-menu"
import { FolderName } from "routes/schedule/folder-manager/folder-name"
import { RegimeButtons } from "slot-definitions"
import { RemoveFolder } from "routes/schedule/folder-manager/remove-folder"
import { RecycleFolder } from "routes/schedule/folder-manager/recycle-folder"
import { useBoundContext } from "lib/@components/binding/use-bound-context"
import { isInPublishedRegime, isInRegimeFolder, not } from "routes/facilities/lib/when-parent-item"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { and } from "lib/logic"
import { PopupButton } from "lib/@components/combos/popup-button"
import { MdArrowDropDown } from "@react-icons/all-files/md/MdArrowDropDown"
import { AddSpace } from "routes/schedule/my-schedules/my-group-types/space/addSpace"
import { useDemand } from "lib/authorization/use-demand"
import { MULTIPLE_REGIMES_DEMAND } from "routes/schedule/folder-manager/multiple-regimes-demand"
import { Secure } from "lib/authorization/secure"
import { Frag } from "lib/@components/slot/frag"

export const folderTypes = {
    folder: {
        icon: "bxs:folder",
        subType: "folder",
    },
    floor: {
        icon: "material-symbols:floor",
        subType: "floor",
    },
    system: {
        icon: "tdesign:system-components",
        subType: "system",
    },
    group: {
        icon: "uil:layer-group",
        subType: "group",
    },
}

RegimeButtons.plug(
    <Secure demand="manageRegime" fallback={<Frag />} if={(c) => c.parentItem.folderHandling} priority={200}>
        <AddFolderButton />
    </Secure>
)
function AddFolderButton() {
    const { parentItem } = useBoundContext()
    const folder = useDialog(<FolderName />, { fullScreen: false })

    const multipleRegimes = useDemand(MULTIPLE_REGIMES_DEMAND)
    return multipleRegimes ? (
        <PopupButton
            endIcon={<MdArrowDropDown />}
            content="Add Folder"
            variant="outlined"
            color="primary"
            startIcon={<Iconify icon="bxs:folder" />}
        >
            <Stack sx={{ p: 1, minWidth: 150 }}>
                <MenuItem color="primary" onClick={add(folderTypes.folder)}>
                    <ListItemIcon>
                        <Iconify icon="bxs:folder" />
                    </ListItemIcon>
                    <ListItemText primary="Folder" />
                </MenuItem>

                <AddSpace subType="floor" content="Floor" icon="material-symbols:floor" />

                <AddSpace />

                <MenuItem color="primary" onClick={add(folderTypes.system)}>
                    <ListItemIcon>
                        <Iconify icon="tdesign:system-components" />
                    </ListItemIcon>
                    <ListItemText primary="System" />
                </MenuItem>

                <MenuItem color="primary" onClick={add(folderTypes.group)}>
                    <ListItemIcon>
                        <Iconify icon="uil:layer-group" />
                    </ListItemIcon>
                    <ListItemText primary="Group" />
                </MenuItem>
            </Stack>
        </PopupButton>
    ) : (
        <Button
            onClick={add(folderTypes.folder)}
            variant="outlined"
            color="primary"
            startIcon={<Iconify icon="bxs:folder" />}
        >
            Add Folder
        </Button>
    )

    function add(type) {
        return async function add() {
            const result = await folder({
                title: `New ${type.subType.titleize()}`,
                label: `${type.subType.titleize()} Name`,
            })
            if (type) {
                Object.assign(result, type)
            }

            if (result) {
                await createSubFolder(parentItem, result)
            }
        }
    }
}

FolderContextMenu.plug(
    <RemoveFolder
        if={and(
            (context) => context.parentItem?.children?.length === 0 && !context.parentItem[PlanRoot],
            isInRegimeFolder,
            not(isInPublishedRegime)
        )}
        priority={2000}
    />
)
FolderContextMenu.plug(
    <RecycleFolder
        if={and(
            (context) => context.parentItem?.children?.length !== 0 && !context.parentItem[PlanRoot],
            isInRegimeFolder,
            not(isInPublishedRegime)
        )}
        priority={2000}
    />
)
