import { AvailableFeatures } from "event-definitions"

export function getAvailableFeatures() {
    return AvailableFeatures.call({ features: [] }).unique("id")
}

const features = {}

/**
 * Registers a new feature with the given name and id.
 *
 * @param {string} name - The name of the feature.
 * @param {string} id - The id of the feature.
 * @return {string} The id of the registered feature.
 */
export function registerFeature(name, id) {
    if (features[id]) return id
    features[id] = true
    AvailableFeatures.handleOnce(({ features }) => {
        features.push({ name, id })
    })
    return id
}
