import { Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import { dataFromEdges } from "lib/permissions/helpers"
import { PagingComponent } from ".."

export function PagedTable(props) {
    return <PagingComponent component={LocalPagedTable} {...props} />
}

function LocalPagedTable({
    headers,
    RowComponent = PlaceHolderRow,
    queryResults,
    orderBy,
    sortOrder,
    handleSortBy,
    onSelectRow,
    size,
    hasSelector,
    selectorProps,
    ...props
}) {
    const rows = dataFromEdges(queryResults)

    const {
        onSelectAll = undefined,
        onSelectIndividual = undefined,
        selectedItems = undefined,
        rowIdentifier = undefined,
    } = selectorProps ?? {}

    let allSelected = false
    if (hasSelector) {
        allSelected = selectedItems && rows.every((rowItem) => selectedItems.includes(rowItem[rowIdentifier]))
        headers = [
            {
                id: "selector",
                isSelector: true,
                onSelect: handleSelectHeader,
                isChecked: allSelected,
            },
            ...headers,
        ]
    }
    return (
        <Table size={size}>
            {headers && (
                <PagedTableHeader
                    orderBy={orderBy}
                    sortOrder={sortOrder}
                    handleSortBy={handleSortBy}
                    headers={headers}
                />
            )}
            <TableBody>
                {rows?.map((row, ix) => (
                    <RowComponent
                        key={row[rowIdentifier] ?? ix}
                        row={row}
                        onSelectRow={onSelectRow}
                        onSelectorClicked={onSelectIndividual}
                        isSelected={selectedItems?.includes(row[rowIdentifier])}
                        {...props}
                    />
                ))}
            </TableBody>
        </Table>
    )

    function handleSelectHeader() {
        const selection = {
            isSelecting: !allSelected,
            items: allSelected
                ? rows.map((rowItem) => rowItem[rowIdentifier])
                : rows
                      .filter((rowItem) => !selectedItems.includes(rowItem[rowIdentifier]))
                      .map((rowItem) => rowItem[rowIdentifier]),
        }
        onSelectAll(selection)
    }
}

function PagedTableHeader({ headers, orderBy, sortOrder, handleSortBy }) {
    return (
        <>
            {headers && (
                <TableHead>
                    <TableRow>
                        {headers.map((header) =>
                            header.isSelector ? (
                                <TableCell key={header.id}>
                                    <Checkbox onClick={header.onSelect} checked={header.isChecked} />
                                </TableCell>
                            ) : (
                                <TableCell
                                    key={header.id}
                                    style={{ boxShadow: "none" }}
                                    sx={{ backgroundColor: "primary" }}
                                    align={header.align || "left"}
                                    padding={header.padding ? "none" : "normal"}
                                    sortDirection={orderBy === header.id ? sortOrder : false}
                                >
                                    {header.canOrderBy !== false ? (
                                        <TableSortLabel
                                            active={orderBy === header.id}
                                            direction={orderBy === header.id ? sortOrder : "asc"}
                                            onClick={() => handleSortBy(header.id)}
                                        >
                                            {header.label}
                                            {orderBy === header.id ? (
                                                <Box component="span" sx={{ visibility: "hidden" }}>
                                                    {sortOrder === "desc" ? "sorted descending" : "sorted ascending"}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    ) : (
                                        header.label
                                    )}
                                </TableCell>
                            )
                        )}
                    </TableRow>
                </TableHead>
            )}
        </>
    )
}

function PlaceHolderRow({ row, onSelectRow }) {
    const tableRowProps = onSelectRow
        ? {
              sx: { cursor: "pointer" },
              hover: true,
              onClick: () => onSelectRow(row),
          }
        : {}

    return (
        <TableRow>
            <TableRow {...tableRowProps}>
                {Object.entries(row).map(([key, value]) => (
                    <TableCell key={key}>{value}</TableCell>
                ))}
            </TableRow>
        </TableRow>
    )
}
