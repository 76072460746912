import { styled } from "@mui/material/styles"
import { Tooltip, tooltipClasses } from "@mui/material"

export const ScheduleToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: theme.palette.background.paper + "D0",
        backdropFilter: "blur(4px)",
        color: theme.palette.text.primary,
        padding: 13,
        border: "1px solid " + theme.palette.primary.main + "A0",
    },
    [`& .${tooltipClasses.arrow}`]: {
        background: "#0000",
        color: theme.palette.primary.main + "A0",
        boxShadow: "none",
        // marginLeft: 25,
        bottom: -3,
        outline: "none",
    },
}))
