import { RegimeItemLeft, TreeToolbar } from "slot-definitions"
import { PlanRoot } from "routes/plan/plan-config/constants"
import { RegimeUserAccessGroup } from "./components/regime-user-access-group"
import { and } from "lib/logic"
import { isEnabled } from "lib/@components/feature"
import { registerFeature } from "lib/features"
import { RegimeAccessButton } from "./components/regime-access-button"

export const FEATURE_FLAG_REGIME_PERMISSIONS = "regimePermissions"
registerFeature("Regime Permissions", FEATURE_FLAG_REGIME_PERMISSIONS)

TreeToolbar("**").plug(
    <RegimeUserAccessGroup priority={-2000} if={and(isRegimeRoot, () => isEnabled(FEATURE_FLAG_REGIME_PERMISSIONS))} />
)
RegimeItemLeft.plug(<RegimeUserAccessGroup if={and(isRegimeRoot, () => isEnabled(FEATURE_FLAG_REGIME_PERMISSIONS))} />)

TreeToolbar("**").plug(
    <RegimeAccessButton priority={-1100} if={and(isRegimeRoot, () => isEnabled(FEATURE_FLAG_REGIME_PERMISSIONS))} />
)

function isRegimeRoot(context) {
    return context.parentItem?.[PlanRoot]
}
